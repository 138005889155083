import React, { ButtonHTMLAttributes } from 'react';

import {
  Container, 
  Loading
} from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>{
    title: string;
    isLoading?: boolean;
    colorSchema?: 'danger' | 'success' | 'default';
}
export function Button({ title, colorSchema = "default", isLoading = false,...rest }: ButtonProps){
  return (
    <Container 
      colorSchema={colorSchema}
      disabled={rest.disabled || isLoading ? true : false}
      {...rest}
    >
        {isLoading ? (
          <Loading colorSchema={colorSchema} />
        ) : title}
    </Container>
  );
}   