import styled from 'styled-components';

export const Container = styled.div``;


export const TopMenu = styled.header`
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    h1 {
        font-size: 18px;
        color: ${({ theme }) => theme.colors.title};
    }
`;


export const Content = styled.div`
    padding: 1.5rem;

    form {
        width: 50%;
        display: flex;

        gap: 1rem;
        
        .btn-filter {
            background: transparent;
            border: 0;
            color: ${({ theme }) => theme.colors.text};
            transition: .2s;

            &:hover {
                color: ${({ theme }) => theme.colors.primary};
            }
        }
        button {
            max-width: 145px;
        }
    }

    main {
        padding-top: 4rem;
    }
`;

export const FormContainer = styled.div`
    height: 100%;
    
    form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* border: ; */
        .input-group > div + div {
            margin-top: 1rem;
        }
    }
`;

export const Item = styled.tr`
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.05) !important;
    }
`;