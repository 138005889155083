import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { FiSearch, FiRefreshCw, FiTrash } from 'react-icons/fi';

import { Button } from '../../components/Button';
import { apiAuth } from '../../utils/api';

import { Select } from '../../components/Select';
import { Loading } from '../../components/Loading';
import {
  TopMenu,
  Content,
  TextLabel,
  ButtonUpdateEnterprises,
  QueryList,
  QueryItem,
  QueryNotItem,
  QueryContainerLoading,
  CircleLoad
} from './styles';

import { ModalContent } from '../../components/ModalContent';
import { Form } from '@unform/web';

import { Checkbox } from '../../components/Checkbox';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';


type EnterpriseData = {
  label: string;
  value: number;
}

type ConstructiveUnits = {
  enterprise_id: number;
  data: EnterpriseData[];
};

interface PreLoadsData {
  id: string;
  name: string;
  building_id: string;
  selection_type: string;
  cost_centers: string;
  constructive_unit: string;
  start_date: string;
  end_date: string;
  status: boolean;
  created_at: string;
  updated_at: string;
}

const selectionTypeLabels = {
  D: 'VENCIMENTO',
  I: 'EMISSÃO',
  P: 'PAGAMENTO DA PARCELA',
}

export function ResultAnalyze() {

  const theme = useTheme();
  /** Enterprises  */
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEnterprises, setIsLoadingEnterprises] = useState(false);
  const [ isLoadingPreloads, setIsLoadingPreloads ] = useState(true);

  const [ enterprises, setEnterprises ] = useState<EnterpriseData[]>([]);
  const [ selectedEnterprise, setSelectedEnterprise ] = useState<EnterpriseData | null>(null);
  const [ costCenters, setCostCenters] = useState<EnterpriseData[] | null>(null);

  /** Constructive units */
  const [ constructiveUnits, setConstructiveUnits ] = useState<ConstructiveUnits | null>(null);
  const [ isLoadingConstructiveUnits, setIsLoadingConstructiveUnits ] = useState(false);
  const [ selectedConstructiveUnits, setSelectedConstructiveUnits ] = useState<EnterpriseData[] | null>(null);

  /** Preloads */
  const [ preloads, setPreloads] = useState<PreLoadsData[]>([]);
  const [ isOpenFilter, setIsOpenFilter ] = useState(false); // modal filter
  const [ selectionType, setSelectionType ] = useState<'D' | 'I' | 'P'>('D'); // D = Vencimento, I = Emissão, P = Pagamento da parcela
  
  const token = localStorage.getItem('@Result-analyze:token');

  /** Submit form register preload */
  async function handleSubmitFilter() {
    if(!selectedEnterprise?.value) {
      return toast.info('Por favor selecione sua obra.')
    }
    
    const newFilter: any = {
      selectionType,
      buildingId: selectedEnterprise.value,
      costCenters: costCenters ? costCenters.map(value => value.value).join(',') : null,
    };

    if(selectedConstructiveUnits) {
      newFilter.constructiveUnits = selectedConstructiveUnits.map(value => value.value).join(',');
    }
    try {
      setIsLoading(true);
      if(token) {
        const api = apiAuth(token);
        const { data } = await api.post('/result-analyze', newFilter); 
        if(data) {
          toast.info('Sua consulta está sendo carregada, você será notificado assim que ficar pronta.')

          const formatCostCenters = (data.cost_centers.split(',')).join(', ');
          const selection_type:  'D' | 'I' | 'P' = data.selection_type;

          const formatData = {
            ...data,
            selection_type: selectionTypeLabels[selection_type],
            cost_centers: formatCostCenters,
            name: selectedEnterprise.label,
          }
          setPreloads([...preloads, formatData]);
        }
      }
    } catch (error) {
      
    } finally {
      setIsLoading(false);
      setIsOpenFilter(false);
    }
  }

  async function handleUpdateEnterprises()  {
    try {
      setIsLoadingEnterprises(true);
      
      if(!token) return;
      
      const api = apiAuth(token);
      
      const { data } = await api.get(`/enterprises?updateList=true`);
      
      if(data) {
        const enterpriseFormat = data.map((enterprise: any) => ({
          label: `${enterprise.id} - ${enterprise.name}`,
          value: enterprise.id
        }));
  
        setEnterprises(enterpriseFormat);
        toast.success('Lista de empreendimentos atualizado com sucesso!')
      }
    } catch (error:any) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingEnterprises(false);
    }
  }

  async function handleOpenFilters() {
    setIsOpenFilter(true);
    /** Get constructives units */
    if(selectedEnterprise?.value && selectedEnterprise.value !== constructiveUnits?.enterprise_id) {
      if(token) {
        try {
          setIsLoadingConstructiveUnits(true);
  
          const api = apiAuth(token);
          
          const { data } = await api.get(`/constructive-unit/${selectedEnterprise.value}`);
          
          const formatData = data.map((unit: any) => ({
            label: `${unit.id} - ${unit.description}`,  
            value: unit.id,  
          }));
  
          setConstructiveUnits({
            enterprise_id: selectedEnterprise.value,
            data: formatData,
          });
        } catch (error:any) {
          
        } finally {
          setIsLoadingConstructiveUnits(false);
        }
      }
    }
  }

  async function handleSelectedBuild(building: any) {

    if(building && token) {
        try {
          setSelectedEnterprise(building);
          setIsLoadingConstructiveUnits(true);
          const api = apiAuth(token);
              
          const { data } = await api.get(`/constructive-unit/${building.value}`);
          
          const formatData = data.map((unit: any) => ({
            label: `${unit.id} - ${unit.description}`,  
            value: unit.id,  
          }));
  
          setConstructiveUnits({
            enterprise_id: building.value,
            data: formatData,
          });
          
        } catch (error) {
          
        } finally {
          setIsLoadingConstructiveUnits(false);
        }
        
    }
      
  }
  useEffect(() => {
    async function getEnterprises() {
      if(token) {
        setIsLoadingEnterprises(true);
        const api = apiAuth(token);
        const { data } = await api.get('/enterprises');
        const enterpriseFormat = data.map((enterprise: any) => ({
          label: `${enterprise.id} - ${enterprise.name}`,
          value: enterprise.id
        }));
  
        if(data) setEnterprises(enterpriseFormat);
        
        setIsLoadingEnterprises(false);
      }
    }
    getEnterprises();

    async function getPreloadedWorks() {
      if(token) {
        const api = apiAuth(token);
        const { data } = await api.get('/preloaded-works');
        if(data){
          const formatData = data.map((item: any) => {
            if(item.cost_centers) {
              const formatCostCenters = (item.cost_centers.split(',')).join(', ');
              return {
                ...item,
                name: `${item.building_id} - ${item.name}`,
                cost_centers: formatCostCenters,
              }
            }
            return item;
          });
          setPreloads(formatData);
        }  
        setIsLoadingPreloads(false); 
      }
    }
    getPreloadedWorks();
  }, [token]);

  async function handleDeletePreload(id: string) {
      const confirmed = await Swal.fire({
        title: 'Deseja realmente apagar essa consulta?',
        icon: 'error',
        html: 'Você está preste a deletar essa consulta, confirme abaixo para deletar.',
        confirmButtonText: 'Deletar agora',
        cancelButtonText: 'Cancelar',
        focusConfirm: true,
        confirmButtonColor: theme.colors.danger,
        iconColor: theme.colors.danger,
        showCancelButton: true,
        cancelButtonColor: theme.colors.text,
        cancelButtonAriaLabel: 'Cancelar',
        showCloseButton: true,
      });

      if(confirmed.isConfirmed) {
        try {
          if(token) {
            setIsLoading(true);
            
            const api = apiAuth(token);
            await api.delete(`/preloaded-works/${id}`);
            
            const updatePreloads = preloads.filter(preload => preload.id !== id);
            setPreloads(updatePreloads);

            toast.success('Consulta deletada com sucesso.');
          }
        } catch (error: any) {
            if(error.response) {
              return toast.error(error.response.data.message);
            }
            toast.error('Erro inesperado, tente mais tarde.');
        }finally {
          setIsLoading(false);
        }
      }
  }

  return (
    <>
      <Loading isLoading={isLoading}/>
      <ModalContent isOpen={isOpenFilter} onRequestClose={() => setIsOpenFilter(false)} title='Opções de consulta'>
        <Form onSubmit={handleSubmitFilter}>

            <div style={{ marginTop: 16, marginBottom: 32 }}>
              <TextLabel><strong>Obra</strong></TextLabel>
              <div style={{
                display: 'flex',
                gap: 8,
              }}>
                <Select 
                    icon={FiSearch}
                    options={enterprises}
                    isLoading={isLoadingEnterprises}
                    placeholder="Selecione a obra"
                    onChange={(value: any) => handleSelectedBuild(value)}
                  />
                  <ButtonUpdateEnterprises title="Atualizar empreendimentos" type='button' onClick={handleUpdateEnterprises}>
                    <FiRefreshCw size={16} />
                  </ButtonUpdateEnterprises>
              </div>
            </div>
            <div style={{ marginTop: 16, marginBottom: 32 }}>
              <TextLabel><strong>Centro de custos</strong></TextLabel>
              <TextLabel>
                Adicione os centros de custos associado a esta obra.
              </TextLabel>
              <Select 
                options={enterprises}
                isLoading={isLoadingEnterprises}
                placeholder="Selecione o centro de custos"
                onChange={(value: any) => setCostCenters(value)}
                isMulti 
              />
            </div>

            <div style={{ marginTop: 16, marginBottom: 32 }}>
              <TextLabel><strong>Unidades construtivas</strong></TextLabel>
              <TextLabel>
                Filtre por unidade construtiva
              </TextLabel>
              <Select 
                options={constructiveUnits?.data}
                isLoading={isLoadingConstructiveUnits}
                placeholder="Selecione as unidades construtivas"
                onChange={(value: any) => setSelectedConstructiveUnits(value)}
                isMulti 
              />
            </div>

            <div style={{ marginTop: 16, marginBottom: 32 }}>
              <TextLabel><strong>Seleção da data do período</strong></TextLabel>
              <div style={{
                display: 'flex',
                gap: 8,
                marginTop: 8,
              }}>
                <Checkbox onClick={() => setSelectionType('D')} isChecked={selectionType === "D"}>Vencimento</Checkbox>
                <Checkbox onClick={() => setSelectionType('I')} isChecked={selectionType === "I"}>Emissão</Checkbox>
                <Checkbox onClick={() => setSelectionType('P')} isChecked={selectionType === "P"}>Pagamento da parcela</Checkbox>
              </div>
            </div>
            
            <Button title='Salvar opção de consulta' type='submit' colorSchema='success'/>
        </Form>
      </ModalContent>
       <TopMenu>
          <h1>Análise de resultado</h1>
          <div>
            <Button type='submit' onClick={handleOpenFilters} title='Criar nova consulta'/>
          </div>

        </TopMenu>
        <Content>
          
          <main>
            <TextLabel><strong>Consultas</strong></TextLabel>
            {isLoadingPreloads ? (
              <QueryContainerLoading>
                <CircleLoad />
              </QueryContainerLoading>
            ) : (
              <QueryList>
                 
                {preloads.length > 0 ? (
                  preloads.map(item => (
                    item.status ? (
                      <QueryItem key={item.id}>
                          <Link to={`/dashboard/result-analyze/${item.id}?buildingId=${item.building_id}`}>
                            <div>
                              <TextLabel>Obra:</TextLabel>
                              <strong>{item.name}</strong>
                            </div>
        
                            <div>
                              <TextLabel>Centro de custos:</TextLabel>
                              <strong>{item.cost_centers || "*"}</strong>
                            </div>
        
                            <div>
                              <TextLabel>Unidade construtiva:</TextLabel>
                              <strong>{item.constructive_unit || "*"}</strong>
                            </div>
                          
                            <div>
                              <TextLabel>Seleção da data do período:</TextLabel>
                              <strong>{item.selection_type}</strong>
                            </div>
                          </Link>
                      
                        <div className='edit' onClick={() => handleDeletePreload(item.id)}>
                          <FiTrash size={16}/>
                        </div>
                      </QueryItem>
                    ) : (
                      <QueryItem>
                        <span className='disabled'>
                          <div>
                            <TextLabel>Obra:</TextLabel>
                            <strong>{item.name}</strong>
                          </div>
      
                          <div>
                            <TextLabel>Centro de custos:</TextLabel>
                            <strong>{item.cost_centers || "*"}</strong>
                          </div>
      
                          <div>
                            <TextLabel>Unidade construtiva:</TextLabel>
                            <strong>{item.constructive_unit || "*"}</strong>
                          </div>
                        
                          <div>
                            <TextLabel>Seleção da data do período:</TextLabel>
                            <strong>{item.selection_type}</strong>
                          </div>
                        </span>
                      <div className='loading'>
                        <span>Processando dados...</span>
                      </div>
                      <div className='edit' onClick={() => {}}>
                        <FiTrash size={16}/>
                      </div>
                    </QueryItem>
                    )
                  ))
                ): (
                  <QueryNotItem>
                    Você não possui nenhuma consulta pré carregada.
                  </QueryNotItem>
                )}
              </QueryList>
            )}
          </main>
        </Content>
    </>
  );
}
