import { ComponentType, useState } from 'react';

import ReactSelect, {
    StylesConfig,
    Props
} from 'react-select';
import { IconBaseProps } from 'react-icons';
import { Container } from './styles';
import { useTheme } from 'styled-components';

interface SelectProps extends Props {
    icon?: ComponentType<IconBaseProps>;
    value?: any;
}

export function Select({ icon: Icon, value, ...rest }: SelectProps) {

    const [ isFocused, setIsFocused ] = useState(false);
    const [ isFilled, setIsFilled ] = useState(false);
    const { colors } = useTheme();
    
    const stylesSelect: StylesConfig = {
        control: (styles, props) => ({ 
            ...styles, 
            background: "#EFEFF5", 
            position: 'relative',
            boxShadow: props.isFocused ? `0 0 0 1px ${colors.primary}` : 'none'
        }),
        placeholder: (styles) => ({ 
            ...styles, 
            color: "#91939F", 
            fontSize: 14, 
            paddingLeft: !!Icon ? 32 : 8,
        }),
        input: (styles) => ({ 
            ...styles, 
            background: 'transparent', 
            paddingLeft: !!Icon ? 32 : 8,
        }),
        singleValue: (styles) => ({ ...styles, paddingLeft: !!Icon ? 32 : 8 })
    };

    function handleOnFocus() {
        setIsFocused(true);
    }

    function handleOnBlur() {
        setIsFocused(false);
        setIsFilled(!!value);
    }

    return (
        <Container isFocused={isFocused} isFilled={isFilled}>
            {Icon && <Icon size={16}/>}
            <ReactSelect 
                styles={stylesSelect}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                {...rest}
            />
            
        </Container>
    )
}