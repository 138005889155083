import styled, { css } from "styled-components";

interface Props {
    isLoading?: boolean;
}

export const Container = styled.div<Props>`
    display: none;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.3);

    ${({ isLoading }) => isLoading && css`
        display: flex;
    `}    
`;