import { format, sub } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR'

export function getDate() {
    const date = format(new Date(), 'MMMM yyyy', { locale: ptBR });

    return date[0].toUpperCase() + date.slice(1, date.length);
}

export function getLastMonth() {
    const dateLastMonth = sub(new Date(), {
        months: 1,
    });

    const date = format(dateLastMonth, 'MMMM yyyy', { locale: ptBR });

    return date[0].toUpperCase() + date.slice(1, date.length);
}