import { createContext, ReactNode, useContext, useState } from "react";
import { api } from "../utils/api";

export type UserProps = {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    role: "administrator" | "user";
    created_at: string;
    updated_at: string;
};

interface AuthState{
    token: string;
    user: UserProps;
}

interface SignInCredentials {
    email: string;
    password: string;
}

interface AuthContextDataProps {
    user: UserProps;
    signIn(credentials: SignInCredentials): Promise<void>;
    signOut(): void;
}

const AuthContext = createContext<AuthContextDataProps>({} as AuthContextDataProps);

interface AuthProviderProps {
    children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {

    const [data, setData] = useState<AuthState>(() => {
        const token = localStorage.getItem('@Result-analyze:token');
        const user = localStorage.getItem('@Result-analyze:user');

        if(token && user) {
            return { token, user: JSON.parse(user)};
        }

        return {} as AuthState;
    });

    async function signIn({ email, password }: SignInCredentials) {
        const { data } = await api.post('/sessions', {
            email,
            password,
        });

        const { token, user } = data;

        localStorage.setItem('@Result-analyze:token', token);
        localStorage.setItem('@Result-analyze:user', JSON.stringify(user));

        setData({ token, user });
    }

    function signOut() {
        localStorage.removeItem('@Result-analyze:token')
        localStorage.removeItem('@Result-analyze:user');

        setData({} as AuthState);
    }

    return (
        <AuthContext.Provider value={{
            user: data.user,
            signIn,
            signOut
        }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth(): AuthContextDataProps
{
    const context = useContext(AuthContext);
    
    if(!context){
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}