import {
    Routes as RoutesDOM,
    Route
} from 'react-router-dom';
import { PrivateRoute } from './Route';
import { Dashboard } from '../pages/Dashboard';
import { SignIn } from '../pages/SignIn';
import { Users } from '../pages/Users';
import { UIDashboard } from '../components/UIDashboard';
import { ForgotPassword } from '../pages/ForgotPassword';
import { ResetPassword } from '../pages/ResetPassword';
import { ResultAnalyze } from '../pages/ResultAnalyze';

export function Routes() {
    return (
        <RoutesDOM>
            <Route path="/" element={<SignIn />}/>
            <Route path="/forgot-password" element={<ForgotPassword />}/>
            <Route path="/reset-password" element={<ResetPassword />}/>
            <Route path="/dashboard/result-analyze" element={
                <PrivateRoute>
                    <UIDashboard>
                        <ResultAnalyze />
                    </UIDashboard>
                </PrivateRoute>
            }/>
            <Route path="/dashboard/result-analyze/:id" element={
                <PrivateRoute>
                    <UIDashboard>
                        <Dashboard />
                    </UIDashboard>
                </PrivateRoute>
            }/>
            <Route path="/dashboard/users" element={
                <PrivateRoute>
                    <UIDashboard>
                        <Users />
                    </UIDashboard>
                </PrivateRoute>
            }/>
        </RoutesDOM>
    )
}