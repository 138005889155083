import Lottie from "react-lottie";
import loadingData from './animation-data.json';
import { Container } from "./styles";

interface LoadingProps {
    isLoading?: boolean;
}

export function Loading({ isLoading = false }: LoadingProps) {

    const options = {
        loop: true,
        autoPlay: true,
        animationData: loadingData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return (
        <Container isLoading={isLoading}>
            <Lottie 
                options={options}
                height={300}
                width={400}
            />
        </Container>
    )
}