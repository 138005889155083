import { shade } from "polished";
import styled, { keyframes } from "styled-components";

export const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    position: relative;
`;

export const ContentContainer = styled.div`
    width: calc(100% - 100px);
    position: relative;
    left: 100px;
`;

export const Content = styled.div`
    padding: 1.5rem;

    form {
        width: 50%;
        display: flex;

        gap: 1rem;
        
        .btn-filter {
            background: transparent;
            border: 0;
            color: ${({ theme }) => theme.colors.text};
            transition: .2s;

            &:hover {
                color: ${({ theme }) => theme.colors.primary};
            }
        }
        button {
            max-width: 145px;
        }
    }

    main {
        padding-top: 4rem;
    }
`;

export const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > strong {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.text};
    }

    .actions {
        display: flex;
        align-items: center;

        p {
            font-size: .75rem;
            color: ${({ theme }) => theme.colors.text};
        }

        button {
            padding: .5rem;
            margin-left: .5rem;
            background: ${({ theme }) => theme.colors.background_sidebar};
            border: 0;
            border-radius: 5px;
            display: flex;
            align-items: center;
            font-size: .75rem;
            color: ${({ theme }) => theme.colors.text};

            svg {
                color: ${({ theme }) => theme.colors.text};
                margin-right: .25rem;
            }

            &:hover {
                background: ${({ theme }) => theme.colors.lineStripedTable};
                color: ${({ theme }) => theme.colors.primary};
                svg {
                    color: ${({ theme }) => theme.colors.primary};
                }
            }
        }
    }
`;
export const TopMenu = styled.header`
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    h1 {
        font-size: 18px;
        color: ${({ theme }) => theme.colors.title};
    }

    div {
        width: 100%;
        max-width: 250px;
    }
`;

export const ButtonUpdateEnterprises = styled.button`
    border: 0;
    padding: 8px 16px;
    border-radius: 10px;
    background: ${({ theme }) => theme.colors.background_sidebar};
    color: ${({ theme }) => theme.colors.text};
    transition: .2s;

    &:hover {
        filter: brightness(0.8);
    }
`;


export const TextLabel = styled.span`
    display: block;
    font-size: .75rem;
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: .5rem;
`;

export const Divider = styled.div`
    display: block;
    height: 1px;
    width: 100%;
    background: ${({ theme }) => theme.colors.text};
    margin: .5rem 0 1rem;
    opacity: .2;
`;

export const QueryList = styled.ul`
    list-style: none;
    width: 100%;
`;

const isRotating = keyframes`
    to {
        transform: rotate(1turn);
    }
`;

export const QueryItem = styled.li`
    background: ${({ theme }) => theme.colors.background_sidebar};
    border-radius: 10px;
    display: flex;
    position: relative;

    a, > span {
        font-size: .75rem;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.title};
        flex: 1;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        border-radius: 10px 0px 0px 10px;
        padding: 1rem;
        gap: 4rem;
        border: 0;
        background: ${({ theme }) => theme.colors.background_sidebar};

        &.disabled {
            opacity: 0.8;
        }
    }
    a:hover {
        background: ${({ theme }) => shade(0.05, theme.colors.background_sidebar)};
    }
    .edit {
        height: inherit;
        padding: 1rem 2rem;
        border-radius: 0 10px 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: #E7E7F0;
        color: ${({ theme }) => theme.colors.text};
        transition: all .2s;
        &:hover {
            color: ${({ theme }) => theme.colors.orange};
            background: ${shade(0.05, "#E7E7F0")};
        }
    }
    
    .loading {
        width: 100%;
        height: 100%;
        display: flex; 
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        background: rgba(255,255,255,0.5);
        border-radius: 10px;
        
        span {
            font-size: 14px;
            font-weight: bold;
            color: ${({ theme }) => theme.colors.title};
        }
    }
    & + li {
        margin-top: 1rem;
    }
`;

export const QueryContainerLoading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
`;
export const CircleLoad = styled.div`
    animation: ${isRotating} 1s infinite;
    height: 42px;
    width: 42px;
    /* background: #e5e5e5; */
    border: 6px solid #e5e5e5;
    border-top-color: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
`;

export const QueryNotItem = styled.li`
    background: ${({ theme }) => theme.colors.background_sidebar};
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1rem;
    color: ${({ theme }) => theme.colors.title};
    padding: 1rem;
`;