import { CSSProperties, useEffect } from 'react';
import { motion, Variants } from 'framer-motion';
import { FiX } from 'react-icons/fi';
import { ButtonCloseModal, Container, Header } from './styles';
import { Table } from '../../pages/Dashboard/styles';

const styles: CSSProperties = {
    position: 'fixed',
    display: 'none',
    height: '100vh',
    right: 0,
    top: 0,
    zIndex: 99,
    background: 'white',
    boxShadow: '10px 10px 0 rgba(0, 0, 0, 0.2)',
};

const overlayContainer: CSSProperties = {
    position: 'fixed',
    background: 'rgba(0,0,0,0.08)',
    height: '100vh',
    width: '100%',
    zIndex: 98,
};

interface Props {
    isOpen: boolean;
    onRequestClose: () => void;
    itemSelected?: any;
};

const variants: Variants = {
    open: {
        display: 'flex',
        width: 600,
        visibility: 'visible',
        transition: {
            type: "spring",
            stiffness: 260,
            damping: 20,
        }
    },
    closed: {
        opacity: 0,
        width: 0,
        visibility: 'hidden',
        display: 'none',
        transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 300,
            damping: 25
        }
    }
};

const variantsOverlay: Variants = {
    open: {
        opacity: 1,
        visibility: 'visible'
    },
    closed: {
        opacity: 0,
        visibility: 'hidden',
        
    }
}

const variantsContent: Variants = {
    open: {
        opacity: 1,
        transition: {
            delay: 0.5,
        }
    },
    closed: {
        opacity: 0,
    }
}

export function Modal({ isOpen = false, onRequestClose, itemSelected }: Props) {

    function handleOnRequestClose() {
        if(isOpen) {
            onRequestClose();
        }
    }
    
    function handleEscRequestClose(e: any) {
        if(e.key === "Escape") {
            onRequestClose();
        }
    }

    useEffect(() => {
        document.addEventListener('keyup', handleEscRequestClose);

        return () => document.removeEventListener('keyup', handleEscRequestClose);
    },);

    return (
        <motion.div 
            style={overlayContainer}
            animate={isOpen ? "open": "closed"}
            variants={variantsOverlay}
        >
            <motion.div
                style={styles}
                animate={isOpen ? "open": "closed"}
                variants={variants}
                                
            >
                <motion.div
                    animate={isOpen ? "open": "closed"}
                    variants={variantsContent}
                    style={{
                        width: '100%',
                    }}
                >
                    <Header>
                        <span>resumo realizado</span>
                        <h1>{itemSelected && itemSelected.name}</h1>
                        {itemSelected.wbsCode && (<span>Código: {itemSelected.wbsCode}</span>)}
                        <ButtonCloseModal onClick={handleOnRequestClose}>
                            <FiX size={24}/>
                        </ButtonCloseModal>
                    </Header>
                    <Container>
                        <strong>Listagem</strong>
                        <Table >
                            <thead>
                                <tr>
                                    <th>Cód. Título</th> 
                                    <th>Fornecedor</th> 
                                    <th>Valor do título</th> 
                                    <th>Porcentagem</th> 
                                    <th>Total</th>
                                </tr>
                        </thead>
                        <tbody>
                            {itemSelected.accomplished && itemSelected.accomplished.map((accomplished: any) => (
                                <tr>
                                    <td>{accomplished.outcome.billId}</td>
                                    <td>{accomplished.outcome.creditorName}</td>
                                    <td>{accomplished.totalTile.toLocaleString('pt-BR', { 
                                        currency: 'BRL',
                                        style: 'currency'
                                    })}</td>
                                    <td>{accomplished.rate.toFixed(1)} %</td>
                                    <td>{accomplished.totalRate.toLocaleString('pt-BR', { 
                                        currency: 'BRL',
                                        style: 'currency'
                                    })}</td>
                                </tr>
                            ))}
                        </tbody>
                        </Table>
                    </Container>
                </motion.div>
            </motion.div>
        </motion.div>
        // <motion.div 
        //     style={overlayContainer}
        //     initial={{
        //         opacity: 0,
        //         display: 'none'
        //     }}
        //     animate={{
        //         opacity: 1,
        //         display: 'block',
        //     }}
        //     transition={{
        //         duration: 0.5
        //     }}
        // >
        // </motion.div>
    )
}