import { InputHTMLAttributes, ComponentType, useRef, useState, useEffect} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';

import {
  Container,
  Error,
  InputElement
} from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement>{
    name: string;
    icon?: ComponentType<IconBaseProps>;
}

export function Input({ name, icon: Icon, disabled, ...rest }: InputProps) {

    const inputRef = useRef<HTMLInputElement>(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);
    const [ isFocused, setIsFocused ] = useState(false);
    const [ isFilled, setIsFilled ] = useState(false);

    function handleInputFocus() {
        setIsFocused(true);
    }

    function handleInputBlur() {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.value;
            },
            setValue: (ref, value) => {
                ref.current.value = value
            },
            clearValue: ref => {
                ref.current.value = ''
            },
        });
    }, [fieldName, registerField]);

    return (
        <>
            <Container isFocused={isFocused} isFilled={isFilled} isErrored={!!error}>
                { Icon && <Icon size={16}/>}
                <InputElement 
                    ref={inputRef}
                    name={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    disabled={disabled}
                    defaultValue={defaultValue}
                    {...rest}
                />
            </Container>
            {!!error && <Error>{error}</Error>}
        </>
    );
}