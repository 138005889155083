export class Tree {
      _root: any;

    constructor(root: any) {
        this._root = root || null;
    }

    _traverse(callback: (node: any) => void) {
                
        function goThrough(node: any) {
          callback(node);
          
          node.children.forEach((child: any) => {
            goThrough(child);
          });
        }
        
        goThrough(this._root);
    }
    
    _getNode() {
      return this._root;
    }

    _addNode(value: any, parentValue: any) {
        const newNode = {
          value,
          children: []
        };
    
        if (this._root === null) {
          this._root = newNode;
          return;
        }
    
        this._traverse((node) => {
          if (node.value.wbsCode === parentValue) {
            node.children.push(newNode);
          }
        });
    }

    _removeNode(value: any) {
        this._traverse((node) => {
          node.children.forEach((childNode: any, index: number) => {
            if (childNode.value.wbsCode === value) {
              node.children.splice(index, 1);
            }
          });
        })
      }
    
      _search(value: any) {
        let returnNode = 'Not Found';
        this._traverse((node) => {
          if (node.value.wbsCode === value) {
            returnNode = node;
          }
        });
        return returnNode;
      }
    
      _displayLeafs(parentValue: any) {
        const parentNode = typeof parentValue === 'string' ? this._search(parentValue) : parentValue ;
        let leafsRet: any[] = [];
        if (parentValue.children && !parentValue.children.length) {
          return parentValue;
        }
    
        parentNode.children.forEach((child: any) => {
          leafsRet.push(this._displayLeafs(child));
        });
    
        return leafsRet.flat();
      }

}

export class Node {
      public value;
      public children;

    constructor(value: any, children: any) {
        this.value = value;
        this.children = children;
    }
}
