import React, { Component } from 'react';
import { parseISO, isSameMonth } from 'date-fns';

import { Tree as TreeUtils } from '../../utils/Tree';

interface TreeProps {
    child: any;
    onSelectedItem: (item: any, now: boolean) => void;
    onSelectedPurchaseOrder?: (purchaseOrders: any[] | null) => void;
}

export class Tree extends Component<TreeProps> {
    
    private tree: TreeUtils;

    constructor(props: TreeProps) {
        super(props);
        
        this.tree = new TreeUtils(this.props.child);
               
    }

    handleOpenAccomplished(wbsCode: string, now = false) {
        const item: any = this.tree._search(wbsCode);

        if(item.value.accomplished) {
            this.props.onSelectedItem(item.value, now);
            return;
        }

        this.props.onSelectedItem(item.value, now);
    }

    handleOnRequestClose() {
        this.setState({
            isOpen: false,
        });
    }

    handleOpenAccomplishedQuantity(purchaseOrders: [] | null, accumulated?: boolean) {
        if(this.props.onSelectedPurchaseOrder) {
            let orders: any[] = [];
            
            if(purchaseOrders) {
                const now = new Date();
                
                if(!accumulated) {
                    const filter = purchaseOrders.filter((order: any) => {
                        const orderDate = parseISO(order.date);

                        return isSameMonth(orderDate, now);
                    });
                    orders = filter;
                } else {
                    const filter = purchaseOrders.filter((order: any) => {
                        const orderDate = parseISO(order.date);
                        if(!isSameMonth(orderDate, now)) return true;
                        return false;
                    });
                    orders = filter;
                }
               
            }
            this.props.onSelectedPurchaseOrder(orders);
        }
    }
    renderTree(child: any): React.ReactNode
    {
        const verifyCodeLength = (child.value.wbsCode.split('.')).length;
        
        const totalAccomplished = child.value.totalAccomplished ? (new Intl
        .NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
        .format(child.value.totalAccomplished)) : 0;
        
        const totalAccomplishedOfMonth = child.value.totalAccomplishedOfMonth ? (new Intl
        .NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
        .format(child.value.totalAccomplishedOfMonth)) : 0;
            
        const totalPrice = new Intl
        .NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
        .format(child.value.totalPrice);

        const unitPrice = new Intl
        .NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
        .format(child.value.unitPrice);
        
        /** Calculo e formatação da quantidade do acumulado */
        const calculatedUnitPriceAccumulated = ((child.value.totalAccomplished > 0 && child.value.quantityAccomplishedToBudget > 0) ?  
        (child.value.totalAccomplished / child.value.quantityAccomplishedToBudget) : 0);

        const priceUnitAccumulated = new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits: 2,
        }).format(calculatedUnitPriceAccumulated);
        
        let quantityAccomplishedToBudget = new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits: 2,
        }).format(child.value.quantityAccomplishedToBudget);
        /** Calculo e formatação da quantidade do mês */
        const calculatedUnitPriceMonth = ((child.value.totalAccomplishedOfMonth > 0 && child.value.quantityAccomplishedToBudgetForMonth > 0) ?  
        (child.value.totalAccomplishedOfMonth / child.value.quantityAccomplishedToBudgetForMonth) : 0);

        const priceUnitMonth = new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits: 2,
        }).format(calculatedUnitPriceMonth); 

        let quantityAccomplishedToBudgetForMonth = new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits: 2,
        }).format(child.value.quantityAccomplishedToBudgetForMonth);

        let quantityBudget =  new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits: 2,
        }).format(child.value.quantity);
        // const priceUnitAccumulated = (child.value.totalAccomplished > 0 && child.value.)
        let percentAccomplishedToBudget;
        let percentAccomplishedToBudgetForMonth;

        if(verifyCodeLength < 4) {
            /** Agrupadores não retornam a porcentagem e necessitam calcular neste momento */
            const calculatePercent = (child.value.totalAccomplished * 100) / child.value.totalPrice;
            percentAccomplishedToBudget = child.value.totalPrice === 0 ? 0 : Math.abs(calculatePercent).toFixed(1);

            /** Mês atual */
            const calculatePercentOfMonth = (child.value.totalAccomplishedOfMonth * 100) / child.value.totalPrice;
            percentAccomplishedToBudgetForMonth = child.value.totalPrice === 0 ? 0 : Math.abs(calculatePercentOfMonth).toFixed(1);
        } else {
            /** Serviços: Os serviços do orçamento já possuim calculos da porcentagem */
            percentAccomplishedToBudget = child.value.percentAccomplishedToBudget 
            ? Math.abs(child.value.percentAccomplishedToBudget).toFixed(2) : 0; 

            percentAccomplishedToBudgetForMonth = child.value.percentAccomplishedToBudgetForMonth 
            ? Math.abs(child.value.percentAccomplishedToBudgetForMonth).toFixed(2) : 0;
            
        }
        
        return (
            <>
                <tr key={child.value.wbsCode} style={{ 
                        borderTop: verifyCodeLength === 1 ? "2px solid rgba(0,0,0,0.05)" : "",
                        borderBottom: verifyCodeLength === 1 ? "2px solid rgba(0,0,0,0.05)" : "",
                    }}>
                    {/* Budget */}
                    <td className={verifyCodeLength > 0 && verifyCodeLength < 4 ? 'highlight' : ''} style={{ 
                        color: verifyCodeLength > 1 ? "#91939F" : "#484964",
                    }}>{child.value.wbsCode}</td>
                    <td>{child.value.name}</td>
                    <td>{child.value.unitOfMeasure ? child.value.unitOfMeasure : ""}</td>
                    <td>{child.value.quantity ? quantityBudget : ""}</td>
                    <td>{unitPrice}</td>
                    <td className='highlight position-right' style={{ borderColor: '#C6C7DB' }}>{totalPrice}</td>
                    {/* Acumulado */}
                    <td 
                        onClick={() => child.value.unitOfMeasure !== "vb" && this.handleOpenAccomplishedQuantity(child.value.purchaseOrders || null, true)}
                        style={{
                            cursor: child.value.unitOfMeasure === "vb" ? 'default' : 'pointer',
                        }}
                        className={verifyCodeLength < 4 ? "position-center" : (child.value.totalPrice < child.value.totalAccomplished ? (
                            child.value.unitOfMeasure === "vb" ? 'position-center negative' : "position-center warning") : 'position-center positive')}> 
                        {
                            verifyCodeLength < 4 ? "==" :
                            child.value.unitOfMeasure === "vb" ? `${percentAccomplishedToBudget} %` : quantityAccomplishedToBudget
                        }
                    </td>
                    <td 
                        style={{
                            cursor: child.value.unitOfMeasure === "vb" ? 'default' : 'pointer',
                        }}
                    >
                        {child.value.unitOfMeasure === "vb" ? "" : priceUnitAccumulated}
                    </td>
                    <td onClick={() => this.handleOpenAccomplished(child.value.wbsCode)} style={{ borderColor: '#C6C7DB' }} className='highlight cursor-click position-right'>{totalAccomplished}</td>
                    {/* Mês */}
                    <td
                        onClick={() => child.value.unitOfMeasure !== "vb" && this.handleOpenAccomplishedQuantity(child.value.purchaseOrders || null)}
                        style={{
                            cursor: child.value.unitOfMeasure === "vb" ? 'default' : 'pointer',
                        }}
                        className={verifyCodeLength < 4 ? "position-center" : (child.value.totalPrice < child.value.totalAccomplishedOfMonth ? (
                            child.value.unitOfMeasure === "vb" ? 'position-center negative' : "position-center warning") : 'position-center positive')}
                    >
                        {
                            verifyCodeLength < 4 ? "" :
                            child.value.unitOfMeasure === "vb" ? `${percentAccomplishedToBudgetForMonth} %` : quantityAccomplishedToBudgetForMonth
                        }
                    </td>
                    <td>
                        {child.value.unitOfMeasure === "vb" ? "" : priceUnitMonth}
                    </td>
                    <td onClick={() => this.handleOpenAccomplished(child.value.wbsCode, true)} className='highlight cursor-click position-right'>{totalAccomplishedOfMonth}</td>
                </tr>
                {child.children && child.children.map((item: any) => this.renderTree(item))}
            </>
        )
    }

    render() {
        return (
            <>
                {this.renderTree(this.props.child)}
            </>
        )
    }
}