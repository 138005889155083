import { useState } from 'react';
import logotipo from '../../assets/logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import { Input } from '../../components/Input';
import { FiMail, FiLock } from 'react-icons/fi';
import { Button } from '../../components/Button';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/Auth';
import {
    Container, 
    ContainerForm, 
    Wrapper
} from './styles';
import { Loading } from '../../components/Loading';
import { Form } from '@unform/web';

type FormDataProps = {
    email: string;
    password: string;
}

export function SignIn() {

    const [isLoading, setIsLoading] = useState(false);
    
    const { signIn } = useAuth();
    const navigate = useNavigate();
    
    async function handleOnSubmit(data: FormDataProps) {
        if(!data.email.trim() || !data.password.trim()) 
            return toast.error('Email/Senha são obrigatórios');

        try {
            setIsLoading(true);

            await signIn(data);

            setIsLoading(false);
            
            navigate('/dashboard/result-analyze');

        } catch (error: any) {
            return toast.error(error.response.data.message);
        } finally {
            setIsLoading(false);
        }

    }
    return (
        <>
            <Loading isLoading={isLoading} />
            <Container>
                <Wrapper>
                    <img src={logotipo} alt="Análise de Resultado" />
                    <ContainerForm>
                        <header>
                            <p><strong>Seja Bem vindo</strong></p>
                            <span>Insira suas credencias para ter acesso a sua conta.</span>
                        </header>
                        <Form onSubmit={handleOnSubmit}>
                            <Input 
                                name='email'
                                placeholder='Insira seu Email'
                                type="email"
                                icon={FiMail}
                                />
                            <Input 
                                name='password'
                                placeholder='Insira sua senha'
                                type="password"
                                icon={FiLock}
                            />
                            <Button 
                                title="Entrar"
                                type='submit'
                            />
                        </Form>
                    </ContainerForm>
                    <p>
                        Esqueceu sua senha? <Link to="/forgot-password">Clique aqui.</Link>
                    </p>
                </Wrapper>
            </Container>
        </>
    )
}