import styled from 'styled-components';


export const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
        color: ${({ theme }) => theme.colors.text};

        a {
            color: ${({ theme }) => theme.colors.secondary};
            text-decoration: none;
            transition: 0.2s;
            
            &:hover {
                filter: brightness(0.8);
            }
        }
    }    
`;

export const ContainerForm = styled.div`
    background: var(--shape);
    padding: 24px 64px 32px;
    text-align: center;
    margin: 1.5rem 0;
    width: 100%;
    max-width: 700px;
    
    @media (max-width: 426px) {
        padding: 1.5rem;
    }

    header {
        margin-bottom: 2rem;
        span {
            color: var(--text);
            font-size: 0.75rem;
        }
        
    }

    form {
        > div {
            margin-bottom: 1rem;
        }
    }
`;