import styled from "styled-components";

export const ButtonCloseModal = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    border: 0;
    background: transparent;
    position: absolute;
    right: 2rem;
    top: 1rem;
    z-index: 999;

    svg {
        color: ${({ theme }) => theme.colors.text};
    }

    &:hover {
        background: ${({ theme }) => theme.colors.background_sidebar};
    }
`;

export const Header = styled.div`
    width: 100%;
    position: relative;
    padding: 2rem;

    h1 {
        font-size: 1.25rem;
        color: ${({ theme }) => theme.colors.title};
        margin-bottom: .5rem;
    }

    span {
        display: block;
        margin-bottom: 8px;
        font-size: .75rem;
        font-weight: bold;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.text};

    }
`;

export const Container = styled.div`
    padding: 2rem;
    height: 100%;
    overflow-y: auto;
    height: 100%;
    max-height: 520px;

    > strong {
        display: block;
        margin-bottom: 8px;
        font-size: .75rem;
        font-weight: bold;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.text};

    }

`;

export const ListItem = styled.ul``;

export const Item = styled.li`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1px;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.colors.background};
    div {
        display: flex;
        flex-direction: column;

        strong {
            width: 100%;
            background: ${({ theme }) => theme.colors.background_sidebar};
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.5rem;
            padding: 0.5rem;
            color: #91939F;
            border-color: transparent;
            text-transform: uppercase;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.5rem;
            font-size: .75rem;
        }
    }

    &:hover {
        background: #e8f3ff;
    }

    &.active {
        background: ${({ theme }) => theme.colors.background};
    }
`;
export const DropDownItem = styled.div`
    background: ${({ theme }) => theme.colors.background};
    padding: 0.5rem;

    header {
        margin-bottom: 1rem;
        strong {
            font-size: .75rem;
            ${({ theme }) => theme.colors.title};
        }

        p {
            font-size: .75rem;
        }
    }

    > strong {
        font-size: .75rem;
            ${({ theme }) => theme.colors.title};
    }

    table {
        margin-top: .5rem;
    }
`;

export const HeaderList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1px;

    div {
        display: flex;
        flex-direction: column;

        strong {
            width: 100%;
            background: ${({ theme }) => theme.colors.background_sidebar};
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.5rem;
            padding: 0.5rem;
            color: #91939F;
            border-color: transparent;
            text-transform: uppercase;
        }

    }
`;