import { useState, useRef } from 'react';
import * as Yup from 'yup';
import { FiMail } from 'react-icons/fi';
import logotipo from '../../assets/logo.svg';
import { Form } from '@unform/web';
import { Link, useNavigate } from 'react-router-dom';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import {
  Container,
  Wrapper,
  ContainerForm,
} from './styles';
import { Loading } from '../../components/Loading';
import { getValidationErrors } from '../../utils/getValidationErrors';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { api } from '../../utils/api';

export function ForgotPassword()
{
    const formRef = useRef<FormHandles>(null);
    const navigate = useNavigate();
    const [ isLoading, setIsLoading ] = useState(false);
    // const token = localStorage.getItem('@Result-analyze:token');

    async function handleOnSubmit(data: { email: string }) {
        
        try {
            // if(!token) return;

            setIsLoading(true);
          
            const schema = Yup.object().shape({
                email: Yup.string().email('Este e-mail não parece válido!').required('Informe o seu e-mail.')
            });
            
            await schema.validate(data);
            
            await api.post('/password/forgot', data);
            
            toast.success('Recuperação de senha enviado com sucesso, verifique sua caixa de entrada.');

            navigate('/');

        } catch (error: any) {
            if(error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error);
                formRef.current?.setErrors(errors);

                return toast.error('Verifique o e-mail informado.');
            }   

            return toast.error(error.response.data.message);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <Container>
                <Wrapper>
                    <img src={logotipo} alt="Análise de Resultado" />
                    <ContainerForm>
                        <header>
                            <p><strong>Esqueceu a senha?</strong></p>
                            <span>Para recuperar seu acesso, preencha o campo com o <br/> e-mail cadastrado em sua conta.</span>
                        </header>
                        <Form onSubmit={handleOnSubmit} ref={formRef}>
                            <Input 
                                name='email'
                                placeholder='Insira seu Email'
                                type="email"
                                icon={FiMail}
                                />
                            <Button 
                                title="Entrar"
                                type='submit'
                            />
                        </Form>
                    </ContainerForm>
                    <p>
                        Lembrou da senha? <Link to="/">Efetuar login.</Link>
                    </p>
                </Wrapper>
            </Container>
        </>
    );
}   