import React, { useState } from "react";
import { Sidebar } from "../Sidebar";

import {
    Container,
    Content
} from './styles';

interface Props {
    children?: React.ReactNode;
}

export function UIDashboard({ children }: Props) {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Container>
                <Sidebar isOpen={isOpen} setIsOpen={setIsOpen}/>
                <Content isOpen={isOpen}>
                    {children}
                </Content>
            </Container>
        </>
    )
}