import {
    Navigate,
    RouteProps as ReactRouterProps,
    useLocation,
} from 'react-router-dom';

import { useAuth } from '../hooks/Auth';

interface RouteProps extends ReactRouterProps {
    children: JSX.Element;
}

export function PrivateRoute({ children }: RouteProps) {

    const { user } = useAuth();
    const location = useLocation();
    
    if(!user) {
        return <Navigate to="/" state={{ from: location }} replace/>
    }
    
    return children;
}