import { CSSProperties, useEffect, useState } from 'react';
import { motion, Variants } from 'framer-motion';
import { FiX } from 'react-icons/fi';
import { ButtonCloseModal, Container, DropDownItem, Header, HeaderList, Item, ListItem } from './styles';
import { Table } from '../../pages/Dashboard/styles';
import { format } from 'date-fns';

const styles: CSSProperties = {
    position: 'fixed',
    display: 'none',
    height: '100vh',
    right: 0,
    top: 0,
    zIndex: 99,
    background: 'white',
    boxShadow: '10px 10px 0 rgba(0, 0, 0, 0.2)',
};

const overlayContainer: CSSProperties = {
    position: 'fixed',
    background: 'rgba(0,0,0,0.08)',
    height: '100vh',
    width: '100%',
    zIndex: 98,
};

interface Props {
    isOpen: boolean;
    onRequestClose: () => void;
    itemSelected?: any;
};

const variants: Variants = {
    open: {
        display: 'flex',
        width: 600,
        visibility: 'visible',
        transition: {
            type: "spring",
            stiffness: 260,
            damping: 20,
        }
    },
    closed: {
        opacity: 0,
        width: 0,
        visibility: 'hidden',
        display: 'none',
        transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 300,
            damping: 25
        }
    }
};

const variantsOverlay: Variants = {
    open: {
        opacity: 1,
        visibility: 'visible'
    },
    closed: {
        opacity: 0,
        visibility: 'hidden',
        
    }
}

const variantsContent: Variants = {
    open: {
        opacity: 1,
        transition: {
            delay: 0.5,
        }
    },
    closed: {
        opacity: 0,
    }
}

export function PurchaseOrdersShow({ isOpen = false, onRequestClose, itemSelected }: Props) {

    const [clicked, setClicked] = useState<boolean | null | number>(false);

    function handleOnRequestClose() {
        if(isOpen) {
            onRequestClose();
        }
    }
    
    function handleEscRequestClose(e: any) {
        if(e.key === "Escape") {
            onRequestClose();
        }
    }

    useEffect(() => {
        document.addEventListener('keyup', handleEscRequestClose);

        return () => document.removeEventListener('keyup', handleEscRequestClose);
    },);
    console.log(itemSelected);

    const toggle = (index: number) => {
        if(clicked === index) {
            return setClicked(null);
        }
        setClicked(index);
    }

    return (
        <motion.div 
            style={overlayContainer}
            animate={isOpen ? "open": "closed"}
            variants={variantsOverlay}
        >
            <motion.div
                style={styles}
                animate={isOpen ? "open": "closed"}
                variants={variants}
                                
            >
                <motion.div
                    animate={isOpen ? "open": "closed"}
                    variants={variantsContent}
                    style={{
                        width: '100%',
                    }}
                >
                    <Header>
                        <span>QUANTIDADE</span>
                        <h1>{itemSelected.length > 0 && itemSelected[0].wbsCode}</h1>
                        {itemSelected.wbsCode && (<span>Código: {itemSelected.wbsCode}</span>)}
                        <ButtonCloseModal onClick={handleOnRequestClose}>
                            <FiX size={24}/>
                        </ButtonCloseModal>
                    </Header>
                    <Container>
                        <strong>Listagem</strong>
                        <HeaderList>
                            <div>
                                <strong>Pedido</strong>
                            </div>
                            <div>
                                <strong>Data</strong>
                            </div>
                            <div>
                                <strong>quantidade</strong>
                            </div>
                        </HeaderList>
                        <ListItem>
                            {itemSelected ? itemSelected.map((item: any, index: number) => (
                                <>
                                    <Item onClick={() => toggle(index)} key={index} className={clicked === index ? "active" : ""}>
                                        <div>
                                            <span>{item.orderId}</span>
                                        </div>
                                        <div>
                                            <span>{format(new Date(item.date), 'dd/MM/yyyy')}</span>
                                        </div>
                                        <div>
                                            <span>{new Intl.NumberFormat('pt-BR').format(item.quantity)}</span>
                                        </div>
                                    </Item>
                                    {
                                        clicked === index ? (
                                            <DropDownItem>
                                                <header>
                                                    <strong>Nota:</strong>
                                                    <p>{item.notes}</p>
                                                </header>
                                                <strong>Materiais</strong>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Preço unit.</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.materials.map((material: any) => (
                                                            <tr>
                                                                <td>{material.resourceDescription}</td>
                                                                <td>{new Intl.NumberFormat('pt-BR', {
                                                                    style: 'currency',
                                                                    currency: 'BRL',
                                                                }).format(material.unitPrice)}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </DropDownItem>

                                        ) : ""
                                    }
                                </>

                            )) : ""}
                        </ListItem>
                    </Container>
                </motion.div>
            </motion.div>
        </motion.div>
    );
}