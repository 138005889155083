import styled, { css } from 'styled-components';

interface Props {
    isOpen?: boolean;
}

export const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    position: relative;
`;

export const Content = styled.div<Props>`
    width: calc(100% - 100px);
    position: relative;
    left: 100px;
    transition: all .2s linear;
    ${({ isOpen }) => isOpen && css`
        width: calc(100% - 250px);
        left: 250px;
    `}
`;