import { lighten } from "polished";
import styled, { css } from "styled-components";

type Props = {
    isChecked?: boolean;
};

export const Container = styled.button<Props>`
    width: 100%;
    padding: .5rem 1rem;
    background: ${({ theme }) => theme.colors.background_sidebar};
    border: 2px solid transparent;
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.text};

    ${({ isChecked, theme }) => isChecked && css`
        border-color: ${theme.colors.primary};
        color: ${theme.colors.primary};
        background: ${lighten(0.6, theme.colors.primary)};
    `}
`;