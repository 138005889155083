import styled from "styled-components";

export const ButtonCloseModal = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    border: 0;
    background: transparent;
    position: absolute;
    right: 2rem;
    top: 1rem;
    z-index: 999;

    svg {
        color: ${({ theme }) => theme.colors.text};
    }

    &:hover {
        background: ${({ theme }) => theme.colors.background_sidebar};
    }
`;

export const Header = styled.div`
    width: 100%;
    position: relative;
    padding: 2rem;

    h1 {
        font-size: 1.25rem;
        color: ${({ theme }) => theme.colors.title};
        margin-bottom: .5rem;
    }

    span {
        display: block;
        margin-bottom: 8px;
        font-size: .75rem;
        font-weight: bold;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.text};

    }
`;

export const Container = styled.div`
    padding: 2rem;
    height: 100%;
    overflow-y: auto;
    height: 100%;
    max-height: 520px;

    > strong {
        display: block;
        margin-bottom: 8px;
        font-size: .75rem;
        font-weight: bold;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.text};

    }

`;