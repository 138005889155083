import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    position: relative;
`;

export const ContentContainer = styled.div`
    width: calc(100% - 100px);
    position: relative;
    left: 100px;
`;

export const Content = styled.div`
    padding: 1.5rem;

    form {
        width: 50%;
        display: flex;

        gap: 1rem;
        
        .btn-filter {
            background: transparent;
            border: 0;
            color: ${({ theme }) => theme.colors.text};
            transition: .2s;

            &:hover {
                color: ${({ theme }) => theme.colors.primary};
            }
        }
        button {
            max-width: 145px;
        }
    }

    main {
        padding-top: 4rem;
    }
`;

export const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > strong {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.text};
    }

    .actions {
        display: flex;
        align-items: center;

        p {
            font-size: .75rem;
            color: ${({ theme }) => theme.colors.text};
        }

        button {
            padding: .5rem;
            margin-left: .5rem;
            background: ${({ theme }) => theme.colors.background_sidebar};
            border: 0;
            border-radius: 5px;
            display: flex;
            align-items: center;
            font-size: .75rem;
            color: ${({ theme }) => theme.colors.text};

            svg {
                color: ${({ theme }) => theme.colors.text};
                margin-right: .25rem;
            }

            &:hover {
                background: ${({ theme }) => theme.colors.lineStripedTable};
                color: ${({ theme }) => theme.colors.primary};
                svg {
                    color: ${({ theme }) => theme.colors.primary};
                }
            }
        }
    }
`;

export const GoBack = styled.button`
    margin-bottom: .5rem;
    background: ${({ theme }) => theme.colors.background_sidebar};
    font-size: .75rem;
    border: 0;
    border-radius: 10px;
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: 0.2s;
    &:hover {
        filter: brightness(0.8);
    }
`;

export const TopMenu = styled.header`
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    h1 {
        font-size: 18px;
        color: ${({ theme }) => theme.colors.title};
    }
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 2rem;

    thead {

        th {
            font-size: .65rem;
            padding: .65rem;
            color: ${({ theme }) => theme.colors.title};
            border: 1px solid ${({ theme }) => theme.colors.background_sidebar};
            text-transform: uppercase;
        }

        tr:first-child, tr:nth-child(2) {
            background: ${({ theme }) => theme.colors.background_sidebar};
            width: 100%;
            
            th {
                font-size: 0.5rem;
                padding: 0.5rem;
                color: ${({ theme }) => theme.colors.text};
                border-color: transparent;
                text-transform: uppercase;
            }

            th:first-child {
                border-right: 1px solid ${({ theme }) => theme.colors.lineStripedTable};
            }
        }

        tr:nth-child(2) {
            background: #e8f3ff;
        }


    }

    tbody {
        tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
                
            td {
                padding: .65rem;
                color: ${({ theme }) => theme.colors.title};
                font-size: .65rem;
                border-right: 1px solid ${({ theme }) => theme.colors.background_sidebar};

                &.highlight {
                    color: ${({ theme }) => theme.colors.title};
                    font-weight: bold;
                }

                &.position-center {
                    text-align: center;
                }
                &.position-right {
                    text-align: end;
                }

                &.negative {
                    color: ${({ theme }) => theme.colors.danger};
                    
                    &::before {
                        content: '+';
                        display: inline-block;
                        margin-right: 2px;
                    }
                }
                
                &.warning {
                    color: ${({ theme }) => theme.colors.danger};
                }

                &.positive {
                    color: ${({ theme }) => theme.colors.success};
                }

                &.cursor-click {
                    cursor: pointer;
                }

            }

            &.footer {
                border-top: 4px solid ${({ theme }) => theme.colors.background};
                background: ${({ theme }) => theme.colors.background_sidebar};
               
                td {
                    /* color: ${({ theme }) => theme.colors.shape}; */
                    strong {
                        font-size: .5rem; 
                    } 
                }
            }

            &:hover {
                background: #e8f3ff;
            }
        }

        tr:nth-child(even) {
            background: ${({ theme }) => theme.colors.background_sidebar};
            
            td {
                border-right: 1px solid ${({ theme }) => theme.colors.lineStripedTable};
            }
            &:hover {
                background: #e8f3ff;
            }
        }
    }
`;

export const TextLabel = styled.span`
    display: block;
    font-size: .75rem;
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: .5rem;
`;

export const Divider = styled.div`
    display: block;
    height: 1px;
    width: 100%;
    background: ${({ theme }) => theme.colors.text};
    margin: .5rem 0 1rem;
    opacity: .2;
`;