import { ValidationError } from "yup";

export function getValidationErrors(err: ValidationError) {
    const validationErrors: {
        [key: string]: string;
      } = {};

      err.inner.forEach(error => {
        if(error.path) 
          validationErrors[error.path] = error.message;
      });

      return validationErrors;
}