import logoImage from '../../assets/logo.svg';
import { 
    FiPieChart,
    FiLogOut,
    FiChevronsRight,
    FiX,
    FiUsers
} from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import {
    Container,
    TopContent,
    BottomContent,
    MenuList,
    MenuItem,
    ToggleContainer
} from './styles';
import { useAuth } from '../../hooks/Auth';

interface Props {
    isOpen?: boolean;
    setIsOpen: (value: boolean) => void;
}
export function Sidebar({ isOpen, setIsOpen }: Props) {
    const { user, signOut } = useAuth();
    const location = useLocation();
    
    return (
        <Container isOpen={isOpen}>
            <TopContent>
                <ToggleContainer isOpen={isOpen}>
                    <img src={logoImage} alt="Logotipo" />
                    <button className='close' onClick={() => setIsOpen(false)}>
                        <FiX size={24}/>
                    </button>
                    <button className='open' onClick={() => setIsOpen(true)}>
                        <FiChevronsRight size={24}/>
                    </button>
                </ToggleContainer>
                <MenuList isOpen={isOpen}>
                    <MenuItem isActive={location.pathname === "/dashboard/result-analyze"}>
                        <Link to="/dashboard/result-analyze">
                            <FiPieChart size={24}/>
                            <span> Análise de resultado</span>
                        </Link>
                    </MenuItem>
                </MenuList>
            </TopContent>
            <BottomContent>
                <MenuList isOpen={isOpen}>
                    {user.role === "administrator" && (
                        <MenuItem isActive={location.pathname === "/dashboard/users"}>
                            <Link to="/dashboard/users">
                                <FiUsers size={24}/>
                                <span>Usúarios</span>
                            </Link>
                        </MenuItem>    
                    )}
                    <MenuItem>
                        <button onClick={signOut}>
                            <FiLogOut size={24}/>
                            <span>Sair</span>
                        </button>
                    </MenuItem>
                </MenuList>
            </BottomContent>
        </Container>
    );
}   