import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FiArrowLeft } from 'react-icons/fi';
import { AiOutlineFilePdf, AiOutlineFileExcel } from 'react-icons/ai';
import { format } from 'date-fns';

import { Button } from '../../components/Button';
import { apiAuth } from '../../utils/api';
import { Tree } from '../../components/Tree';
import { Select } from '../../components/Select';
import { Loading } from '../../components/Loading';
import {
  TopMenu,
  Content,
  Table,
  ActionsContainer,
  TextLabel,
  Divider,
  GoBack
} from './styles';
import { Modal } from '../../components/Modal';
import { ModalContent } from '../../components/ModalContent';
import { Form } from '@unform/web';

import { Checkbox } from '../../components/Checkbox';
import { DatePicker } from '../../components/DatePicker';

import { getDate, getLastMonth } from '../../utils/getDateNow';
import { useParams, useNavigate } from 'react-router-dom';
import { PurchaseOrdersShow } from '../../components/PurchaseOrdersShow';

type EnterpriseData = {
  label: string;
  value: number;
}

type TotalResultData = {
  totalCostEstimation: number;
  totalCostEstimationFormat: string;
  totalAccomplished: number;
  totalAccomplishedFormat: string;
  totalAccomplishedOfMonth: number;
  totalAccomplishedOfMonthFormat: string;
};

type ConstructiveUnits = {
  enterprise_id: number;
  data: EnterpriseData[];
};

interface IParams {
  id?: string;
}


export function Dashboard() {

  const { id }: IParams = useParams();
  const navigate = useNavigate();
  const tableDateNow = getDate();
  const tableDateAccumulated = getLastMonth();
  
  /** Enterprises  */
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEnterprises, setIsLoadingEnterprises] = useState(false);
  const [ enterprises, setEnterprises ] = useState<EnterpriseData[]>([]);

  const [ buildingId, setBuildingId ] = useState<string>();
  const [ costCenters, setCostCenters] = useState<EnterpriseData[] | null>(null);
  /** Constructive units */
  const [ constructiveUnits ] = useState<ConstructiveUnits | null>(null);
  const [ isLoadingConstructiveUnits ] = useState(false);
  const [ selectedConstructiveUnits, setSelectedConstructiveUnits ] = useState<EnterpriseData[] | null>(null);
  /** Result */
  const [resultAnalyze, setResultAnalyze] = useState<any[]>([]);
  const [ totalResult, setTotalResult ] = useState<TotalResultData>({} as TotalResultData);

  // purchase orders
  const [ isOpenPurchaseOrders, setIsOpenPurchaseOrders ] = useState(false); // modal purchase orders
  const [ purchaseOrder, setPurchaseOrder ] = useState<any[] | null>([]);

  const [ isOpenModal, setIsOpenModal ] = useState(false); // modal accomplished
  const [ isOpenFilter, setIsOpenFilter ] = useState(false); // modal filter
  const [ selectionType, setSelectionType ] = useState<'D' | 'I' | 'P'>('D'); // D = Vencimento, I = Emissão, P = Pagamento da parcela
  const [ selectionBudget, setSelectionBudget ] = useState<'U' | 'S'>('S'); // U = Update (Atualizar) & S = Save (Salvo) 
  const [ selectionOutcome, setSelectionOutcome ] = useState<'U' | 'S'>('S'); // U = Update (Atualizar) & S = Save (Salvo) 
  
  const [ , setFilter ] = useState<any>({
    selectionType,
    selectionBudget
  });

  const [ itemSelected, setItemSelected ] = useState<any>({});
  const token = localStorage.getItem('@Result-analyze:token');

  
  async function handleExportPdf() {
    if(!token) return;

    try {
      setIsLoading(true);

      const api = apiAuth(token);
      
        const response = await api.get(`/result-analyze/generate-pdf/${buildingId}`, {
        responseType: 'blob',
      });

      const blob = new Blob([ response.data ], {
        type: 'application/pdf',
      });

      const url = window.URL.createObjectURL(blob);

      window.open(url);

      toast.info('Exportação PDF realizado com sucesso!');

    } catch (error: any) {
      if(error.response) {
        toast.error(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }

    return toast.info('Aguarde estamos processando seu documento.');
  }

  async function handleExportXLSX() {

    try {
      if(!token) return;

      setIsLoading(true);
      const api = apiAuth(token);
      const response = await api.get(`/result-analyze/generete-xlsx/${buildingId}`, {
        responseType: 'blob',
      });
      
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      
      const url = window.URL.createObjectURL(blob);
      
      window.open(url);

      toast.info('Exportação XLSX realizado com sucesso!');
      setIsLoading(false);

    } catch (error: any) {
      if(error.response) {
        toast.error(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }

  }

  function handleRequestCloseModal() {
    setIsOpenModal(false);
  }

  function handleOpenItem(item: any, month: boolean) {
    // console.log(item.accomplished);
    if(month) {
      const dateMonth = new Date();

      if(item.accomplished) {
        let accomplishedOfMonth = item.accomplished.filter((title: any) => {
          const issueDate = new Date(title.issueDate);
          return dateMonth.getMonth() === issueDate.getMonth() && dateMonth.getFullYear() === issueDate.getFullYear();
        });
        console.log(accomplishedOfMonth);
        setItemSelected({
          ...item,
          accomplished: accomplishedOfMonth,
        });
      }
      
    } else {
      setItemSelected(item);
    }
    setIsOpenModal(true);
  }

  function handleSubmitFilter(data: any) {
    const newFilter: any = {};
    let startDate = null
    let endDate = null;
    // let costCentersFormat = null;
     
    if(data.startDate && data.endDate) {
      startDate = format(data.startDate, 'yyyy-MM-dd');
      endDate = format(data.endDate, 'yyyy-MM-dd'); 
    }

    newFilter.startDate = startDate;
    newFilter.endDate = endDate;
    newFilter.selectionType = selectionType;
    newFilter.selectionBudget = selectionBudget;
    newFilter.selectionOutcome = selectionOutcome;

    if(costCenters && costCenters.length > 0) {
      newFilter.costCenters = costCenters.map(value => value.value).join(',');
    }

    if(selectedConstructiveUnits) {
      newFilter.constructiveUnits = selectedConstructiveUnits.map(value => value.value).join(',');
    }

    setFilter(newFilter);
    setIsOpenFilter(false);
    toast.info('Opções de consuta atualizada!');
  }

  async function handleUpdateEnterprises()  {
    try {
      setIsLoadingEnterprises(true);
      
      if(!token) return;
      
      const api = apiAuth(token);
      
      const { data } = await api.get(`/enterprises?updateList=true`);
      
      if(data) {
        const enterpriseFormat = data.map((enterprise: any) => ({
          label: `${enterprise.id} - ${enterprise.name}`,
          value: enterprise.id
        }));
  
        setEnterprises(enterpriseFormat);
        toast.success('Lista de empreendimentos atualizado com sucesso!')
      }
    } catch (error:any) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingEnterprises(false);
    }
  }

  
  function handleOpenPurchaseOrder(order: any[] | null) {
    if(order) {
      setPurchaseOrder(order);
      setIsOpenPurchaseOrders(true);
    }
  }
  useEffect(() => {
    //  console.log(id);
    const params = new URLSearchParams(window.location.search);
    const building_id = params.get('buildingId');

    if(building_id) {
      setBuildingId(building_id);
    }

     async function getResult() {
       if(id && token) {
          try {
            setIsLoading(true);
            const api = apiAuth(token);
            const { data } = await api.get(`/result-analyze/${id}`);
            if(data) {
              const total = data.reduce((accumulator: TotalResultData, currentItem: any) => {
                accumulator.totalAccomplished += currentItem.value.totalAccomplished;
                accumulator.totalCostEstimation += currentItem.value.totalPrice;
                accumulator.totalAccomplishedOfMonth += currentItem.value.totalAccomplishedOfMonth;
                return accumulator;
              }, {
                totalAccomplished: 0,
                totalCostEstimation: 0,
                totalAccomplishedOfMonth: 0,
              } as TotalResultData);
      
              const totalData = {
                ...total,
                totalCostEstimationFormat: new Intl.NumberFormat('pt-BR', { style: 'currency',currency: 'BRL' }).format(total.totalCostEstimation),
                totalAccomplishedFormat: new Intl.NumberFormat('pt-BR', { style: 'currency',currency: 'BRL' }).format(total.totalAccomplished),
                totalAccomplishedOfMonthFormat: new Intl.NumberFormat('pt-BR', { style: 'currency',currency: 'BRL' }).format(total.totalAccomplishedOfMonth),
              }
              setResultAnalyze(data);
              setTotalResult(totalData);
            }
            
          } catch (error) {
            
          } finally {
            setIsLoading(false);
          }
       }
     }
     getResult();
  }, [id, token]);

  
  return (
    <>
      <Loading isLoading={isLoading}/>
      <ModalContent isOpen={isOpenFilter} onRequestClose={() => setIsOpenFilter(false)} title='Opções de consulta'>
        <Form onSubmit={handleSubmitFilter}>

            <div style={{ marginBottom: 16}}>
              <TextLabel><strong>Atualizar lista de empreendimentos</strong></TextLabel>
              <Button isLoading={isLoadingEnterprises} title="Atualizar empreendimentos" onClick={handleUpdateEnterprises}/>
              <TextLabel style={{ marginTop: 8 }}>Caso tenha criado um empreedimento recentemente clique no botão para atualizar sua lista.</TextLabel>
            </div>
            
            <Divider />
            <div style={{ marginTop: 16, marginBottom: 32 }}>
              <TextLabel><strong>Centro de custos</strong></TextLabel>
              <TextLabel>
                Adicione os centros de custos associado a esta obra.
              </TextLabel>
              <Select 
                options={enterprises}
                isLoading={isLoadingEnterprises}
                placeholder="Selecione o centro de custos"
                onChange={(value: any) => setCostCenters(value)}
                isMulti 
              />
            </div>

            <div style={{ marginTop: 16, marginBottom: 32 }}>
              <TextLabel><strong>Unidades construtivas</strong></TextLabel>
              <TextLabel>
                Filtre por unidade construtiva
              </TextLabel>
              <Select 
                options={constructiveUnits?.data}
                isLoading={isLoadingConstructiveUnits}
                placeholder="Selecione as unidades construtivas"
                onChange={(value: any) => setSelectedConstructiveUnits(value)}
                isMulti 
              />
            </div>

            <div style={{ marginTop: 16 }}>
              <TextLabel><strong>Seleção da data do período</strong></TextLabel>
              <div style={{
                display: 'flex',
                gap: 8,
                marginTop: 8,
              }}>
                <Checkbox onClick={() => setSelectionType('D')} isChecked={selectionType === "D"}>Vencimento</Checkbox>
                <Checkbox onClick={() => setSelectionType('I')} isChecked={selectionType === "I"}>Emissão</Checkbox>
                <Checkbox onClick={() => setSelectionType('P')} isChecked={selectionType === "P"}>Pagamento da parcela</Checkbox>
              </div>
            </div>

            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <TextLabel><strong>Orçamento</strong></TextLabel>
              <div style={{
                display: 'flex',
                gap: 8,
                marginTop: 8,
                marginBottom: 8
              }}>
                <Checkbox onClick={() => setSelectionBudget('U')} isChecked={selectionBudget === "U"}>Atualizado</Checkbox>
                <Checkbox onClick={() => setSelectionBudget('S')} isChecked={selectionBudget === "S"}>Salvo</Checkbox>
              </div>
              <TextLabel>
                Caso tenha realizado uma consulta nesta obra por padrão irá ser salvo o orçamento atual, em caso de uma atualização na planilha orçamentária selecione a opção <strong>Atualizado</strong>.
              </TextLabel>
            </div>
            {/* setSelectionOutcome */}
            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <TextLabel><strong>Executado</strong></TextLabel>
              <div style={{
                display: 'flex',
                gap: 8,
                marginTop: 8,
                marginBottom: 8
              }}>
                <Checkbox onClick={() => setSelectionOutcome('U')} isChecked={selectionOutcome === "U"}>Atualizado</Checkbox>
                <Checkbox onClick={() => setSelectionOutcome('S')} isChecked={selectionOutcome === "S"}>Salvo</Checkbox>
              </div>
              <TextLabel>
                Por padrão trazemos o realizado já salvo, para trazer dados em tempo real selecione "Atualizado".
              </TextLabel>
            </div>

            <div style={{
              marginTop: 16,
            }}>
              <TextLabel><strong>Data período da consulta</strong></TextLabel>
              <div style={{
                display: 'flex',
                gap: 8,
                marginTop: 8,
                marginBottom: 8
              }}>
                <DatePicker name="startDate" />
                <DatePicker name="endDate"/>
              </div>
                <TextLabel>Por padrão a consulta é realizado baseado no inicio e fim da obra.</TextLabel>
            </div>

            
            <Button title='Salvar opção de consulta' type='submit' colorSchema='success'/>
        </Form>
      </ModalContent>
      {/* Modal */}
      <Modal 
        isOpen={isOpenModal}
        onRequestClose={handleRequestCloseModal}
        itemSelected={itemSelected}
      />
      {/* end Modal */}
      <PurchaseOrdersShow 
        isOpen={isOpenPurchaseOrders}
        onRequestClose={() => setIsOpenPurchaseOrders(false)}
        itemSelected={purchaseOrder}
      />
       <TopMenu>
          <div>
            <GoBack onClick={() => navigate('/dashboard/result-analyze')}>
              <FiArrowLeft /> Voltar
            </GoBack>
            <h1>Análise de resultado</h1>
          </div>
          
        </TopMenu>
        <Content>
          <main>
            <ActionsContainer>
              <strong>Resultado</strong>
              <div className="actions">
                  <p>Exportar: </p>
                  <button onClick={handleExportPdf}>
                      <AiOutlineFilePdf size={16}/>
                      PDF
                  </button>
                  <button onClick={handleExportXLSX}>
                      <AiOutlineFileExcel size={16}/>
                      XLSX
                  </button>
              </div>
            </ActionsContainer>
            
            <Table>
              <thead>
                  <tr>
                      <th colSpan={6} style={{ borderRightColor: '#C6C7DB' }}>ORÇAMENTO</th> 
                      <th colSpan={6}>EXECUTADO</th> 
                  </tr>
                  <tr>
                    <th colSpan={6} style={{ borderRightColor: '#C6C7DB' }}>DESPESA</th>
                    {/* <th colSpan={}>TOTAL</th> */}
                    <th colSpan={3} style={{ borderRightColor: '#C6C7DB' }}>ACUMULADO ATÉ {tableDateAccumulated}</th>
                    <th colSpan={3}>{tableDateNow}</th>
                  </tr>
                  <tr>
                      <th>Código</th> 
                      <th>Serviço</th> 
                      <th>Unidade</th> 
                      <th>Qtde</th> 
                      <th>Valor unitário</th> 
                      <th style={{ borderColor: '#C6C7DB' }}>Total</th> 
                      {/* Acumulado */}
                      <th>Qtde ou %</th>
                      <th>Cust. Unit.</th>
                      <th style={{ borderColor: '#C6C7DB' }}>Total</th>
                       {/* Mês  */}
                       <th>Qtde ou %</th>
                       <th>Cust. Unit.</th>
                      <th>Total</th>
                  </tr>
              </thead>
              <tbody>
                  {resultAnalyze && (
                    <>
                      {
                        resultAnalyze.map((item, index) => (
                          <Tree
                            key={String(index)}
                            child={item} 
                            onSelectedItem={(selectedItem, now) => handleOpenItem(selectedItem, now)} 
                            onSelectedPurchaseOrder={(purchase) => handleOpenPurchaseOrder(purchase)}
                          />
                        ))
                      }
                      <tr className='footer'>
                        <td style={{ textAlign: 'right' }} colSpan={5}>
                          <strong>TOTAL ORÇAMENTO</strong>
                        </td>
                        <td className='highlight' style={{ textAlign: 'right', borderColor: '#ABACBD' }}>
                          {totalResult.totalCostEstimationFormat}
                        </td>
                        <td>
                          <strong>TOTAL</strong>
                        </td>
                        <td colSpan={2} className='highlight' style={{ textAlign: 'right', borderColor: '#ABACBD' }}>
                          {totalResult.totalAccomplishedFormat}
                        </td>
                        <td>
                          <strong>TOTAL</strong>
                        </td>
                        <td colSpan={2} className='highlight' style={{ textAlign: 'right' }}>
                          {totalResult.totalAccomplishedOfMonthFormat}
                        </td>
                      </tr>
                    </>)}
              </tbody>
            </Table>
          </main>
        </Content>
    </>
  );
}
