export const theme = {
    colors: {
        background: "#F7F7FA",
        background_sidebar: "#EFEFF5",
        text: "#91939F",
        primary: "#004FA4",
        danger: '#F26464',
        orange: "#f17300",
        success: '#4BDE97',
        secondary: "#95BC48",
        title: "#484964",
        shape: "#fff",
        lineStripedTable: "#E8E8E9",
    }
}