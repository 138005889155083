import styled, { css, keyframes } from 'styled-components';

interface Props {
    isOpen?: boolean;
}
export const Container = styled.div<Props>`
    width: 100px;
    height: 100%;
    background: ${({ theme }) => theme.colors.background_sidebar};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1rem 4rem;
    position: fixed;
    transition: width .2s linear;

    ${({ isOpen }) => isOpen && css`
        width: 250px;
    `}
`;

export const TopContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ToggleContainer = styled.div<Props>`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 4rem;
    width: 100%;
    transition: .2s;

    > img {
        height: 32px;
        margin-right: .5rem;
    }
    
    button {
        background: transparent;
        border: 0;
        height: 100%;
        display: none;
        align-items: center;
        justify-content: center;
        transition: .2s;

        svg {
            color: ${({ theme }) => theme.colors.text};
            transition: .2s;
        }

        &:hover {
            svg {
                color: ${({ theme }) => theme.colors.primary};
            }
        }

    }
    
    ${({ isOpen }) => isOpen ? css`

        .close {
            display: flex;
        }

        .open {
            display: none;
        }
    ` : css`
        .close {
            display: none;
        }

        .open {
            display: flex;
        }
    `}
`;

export const BottomContent = styled.div`
    width: 100%;
`;

const animateMenuItem = keyframes`
    from {
        display: none;
        opacity: 0;
        transform: translateX(-4px);
    }
    to {
        display: flex;
        opacity: 1;
        transform: translateX(0);
    }
`;

export const MenuList = styled.ul<Props>`
    list-style: none;
    width: 100%;

    ${({ isOpen }) => isOpen && css`
        li {
            align-items: flex-start;

            a, button {
                display: flex;
                justify-content: start;

                span {
                    display: inline-block;
                    animation: ${animateMenuItem} .5s ease-in;
                }
            }
        }
    `}
`;

interface MenuItemProps extends Props{
    isActive?: boolean;
}

export const MenuItem = styled.li<MenuItemProps>`
    display: flex;
    align-items: center;
    transition: .2s;

    &:hover {
        a, svg {
            color: ${({ theme }) => theme.colors.primary};
        }
    }

    a, button {
        width: 100%;
        font-size: 0.75rem;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.text};
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        transition: .2s;
        background: transparent;
        border: 0;

        span {
            transition: .2s;
            display: none;
        }
    }

    svg {
        color: ${({ theme }) => theme.colors.text};
        font-size: 24px;
    }

    + li {
        margin-top: 32px;
    }

    ${({ isActive }) => isActive && css`
        a {
            color: ${({ theme }) => theme.colors.primary};
        }
        svg {
            color: ${({ theme }) => theme.colors.primary};
        }
    `}

  
`;