import React, { useEffect, useState, useRef } from 'react';

import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

import { useField } from '@unform/core';

import { FiCalendar } from 'react-icons/fi';
import { ContainerInput } from './styles';

import 'react-datepicker/dist/react-datepicker.css';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
    name: string;
}

registerLocale('pt-BR', ptBR);

interface InputProps extends React.HTMLProps<HTMLInputElement>{
    isFocused?: boolean;
    isFilled?: boolean;
}
const Input = ({isFocused = false, isFilled = false, ...rest}: InputProps, ref: React.Ref<HTMLInputElement>) => {
    
    return (
        <ContainerInput isFocused={isFocused} isFilled={isFilled}>
            <FiCalendar />
            <input 
                {...rest}
            />
        </ContainerInput>
    );
}

export function DatePicker({ name, ...rest}: Props) {

    const datepickerRef = useRef(null);
    const { fieldName, registerField, defaultValue } = useField(name);
    const [ date, setDate ] = useState(defaultValue || null);
    const [ isFocused, setIsFocused ] = useState(defaultValue || null);
    
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: datepickerRef.current,
            path: 'props.selected',
            clearValue: (ref: any) => {
                ref.clear();
            },
        });
    }, [fieldName, registerField]);

    return (
        <ReactDatePicker 
            ref={datepickerRef}
            selected={date}
            onChange={setDate}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            locale="pt-BR"
            customInput={React.createElement(React.forwardRef(Input), {
                isFilled: !!date,
                isFocused: isFocused,
            })}
            {...rest}
        />
    );
}