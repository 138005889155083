import {
    Container
} from './styles';

interface Props {
    isChecked?: boolean;
    onClick?: () => void;
    children?: string;
}

export function Checkbox({ onClick, children, isChecked = false }: Props) {
    return (
        <Container onClick={onClick} type='button' isChecked={isChecked}>
            {children}
        </Container>
    )
}