import styled from 'styled-components';

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 2rem;

    thead {

        th {
            font-size: .75rem;
            padding: .75rem;
            color: ${({ theme }) => theme.colors.title};
            border: 1px solid ${({ theme }) => theme.colors.background_sidebar};
            text-transform: uppercase;
        }

        tr:first-child {
            background: ${({ theme }) => theme.colors.background_sidebar};
            width: 100%;
            
            th {
                font-size: 0.5rem;
                padding: 0.5rem;
                color: ${({ theme }) => theme.colors.text};
                border-color: transparent;
                text-transform: uppercase;
            }

            th:first-child {
                border-right: 1px solid ${({ theme }) => theme.colors.lineStripedTable};
            }
            
        }


    }

    tbody {
        tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
                
            td {
                padding: .75rem;
                color: ${({ theme }) => theme.colors.title};
                font-size: .75rem;
                border-right: 1px solid ${({ theme }) => theme.colors.background_sidebar};

                &.highlight {
                    color: ${({ theme }) => theme.colors.title};
                    font-weight: bold;
                }

                &.position-center {
                    text-align: center;
                }
                &.position-right {
                    text-align: end;
                }

                &.negative {
                    color: ${({ theme }) => theme.colors.danger};
                    
                    &::before {
                        content: '+';
                        display: inline-block;
                        margin-right: 2px;
                    }
                }

                &.positive {
                    color: ${({ theme }) => theme.colors.success};
                }

                &.cursor-click {
                    cursor: pointer;
                }

            }
        }

        tr:nth-child(even) {
            background: ${({ theme }) => theme.colors.background_sidebar};
            
            td {
                border-right: 1px solid ${({ theme }) => theme.colors.lineStripedTable};
            }
        }
    }
`;