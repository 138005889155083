import styled from "styled-components";

type ContainerProps = {
    isFocused?: boolean;
    isFilled?: boolean;
};

export const Container = styled.div<ContainerProps>`
    width: 100%;
    position: relative;
    
    > svg {
        position: absolute;
        margin-right: 4px;
        z-index: 99;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);

        color: ${({ isFilled, isFocused, theme }) => isFilled || isFocused ? theme.colors.primary : theme.colors.text };
    }


`;