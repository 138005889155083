import axios from "axios";

export const api = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
});


export const apiAuth = (token: string) => {

    const instance = axios.create({
        baseURL: process.env.REACT_APP_URL_API,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return instance;
} 

