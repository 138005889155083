import { useEffect, useState, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { Input } from '../../components/Input';
import { 
  FiUser,
  FiMail,
  FiLock,
  FiSettings
} from 'react-icons/fi';

import { ModalContent } from '../../components/ModalContent';

import { Table } from '../../components/Table/styles';
import { UserProps } from '../../hooks/Auth';
import { apiAuth } from '../../utils/api';
import {
  Container,
  Content,
  FormContainer,
  Item,
  TopMenu
} from './styles';
import { Select } from '../../components/Select';
import { Button } from '../../components/Button';
import { toast } from 'react-toastify';
import { FormHandles } from '@unform/core';
import { getValidationErrors } from '../../utils/getValidationErrors';
import { useTheme } from 'styled-components';

interface UserDataProps extends UserProps {
  role_format: string;
  created_format: string;
}

type FormDataProps = Omit<UserProps, 'created_at' | 'updated_at'>;

export function Users()
{
    const theme = useTheme();
    const registerFormRef = useRef<FormHandles>(null);
    const updateFormRef = useRef<FormHandles>(null);
    const [ users, setUsers ] = useState<UserDataProps[]>([]);
    /** Loading for button */
    const [ isLoading, setIsLoading ] = useState(false);
    /** Open modal (create | update) */
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [ isOpenViewModal, setIsOpenViewModal ] = useState<boolean>(false);
    const [ userSelected, setUserSelected ] = useState<UserDataProps | null>(null);
    const [ roleUserRegister, setRoleUserRegister ] = useState('');
    const [ updateRoleUser, setUpdateRoleUser ] = useState({
      value: 'user',
      label: 'Usuário comum',
    });

    const token = localStorage.getItem('@Result-analyze:token');
    
    useEffect(() => {
      async function getUsers() {
        if(token) {
          const api = apiAuth(token);
          const { data } = await api.get<UserProps[]>('/users');
          const format = data.map(user => ({
            ...user,
            role_format: user.role === "administrator" ? "Administrador" : "Usuário", 
            created_format: (new Date(user.created_at)).toLocaleDateString('pt-BR'),
          }))
          setUsers(format);
        }
      }
      getUsers();
    }, [token]);

    async function handlSubmitRegisterUser(formdata: FormDataProps) {
      
      try {
        setIsLoading(true);
        registerFormRef.current?.setErrors({});
        /** Schema Data Form User */
        const schema = Yup.object().shape({
          first_name: Yup.string().required('Nome é obrigatório'),
          last_name: Yup.string().required('Sobrenome é obrigatório'),
          email: Yup.string().email('Informe um E-mail válido.').required('E-mail é obrigatório'),
          password: Yup.string().min(6, 'Senha deve ter no minimo 6 caracteres').required('Senha é obrigatória'),
        });

        await schema.validate(formdata, {
          abortEarly: false,
        });


        if(token) {
          const api = apiAuth(token);
         
          const { data } = await api.post('/users', {
            ...formdata,
            role: roleUserRegister,
          });
         
          if(data) {
            setUsers([...users, {
              ...data,
              role_format: data.role === "administrator" ? "Administrador" : "Usuário", 
              created_format: (new Date(data.created_at)).toLocaleDateString('pt-BR'),
            }]); 
            setIsOpen(false);
            toast.success('Usuário cadastrado com sucesso');
          }
        }
      } catch (error: any) {
        
        if(error instanceof Yup.ValidationError) {
           const validationErrors = getValidationErrors(error); 

            registerFormRef.current?.setErrors(validationErrors);

            return toast.error('Verifique os dados preenchidos');
          }
          
        if(error.response) {
          return toast.error(error.response.data.message);
        }
        toast.error("Erro inesperado, tente novamente mais tarde.")
      } finally {
        setIsLoading(false);
      }  
    }

    function handleOpenViewUser(id: string) {
      
      const user = users.find(find => find.id === id);
      // console.log(user);
      if(user) {
        const data = Object.assign(user, { password: '' })
        setUserSelected(data);
        const role = user.role === "user" ? {value: 'user', label: 'Usuário comum'} : {value: 'administrator', label: 'administrator'};
        setUpdateRoleUser(role);
        setIsOpenViewModal(true);

      }
    }

    useEffect(() => {
      if(userSelected) { 
        updateFormRef.current?.setData(userSelected);
      }
    }, [userSelected]);

    async function handleSubmitUpdateUser(formdata: FormDataProps) {
      try {
        setIsLoading(true);
        updateFormRef.current?.setErrors({});
        const schema = Yup.object().shape({
          first_name: Yup.string().required('Nome é obrigatório'),
          last_name: Yup.string().required('Sobrenome é obrigatório'),
          email: Yup.string().email('Informe um E-mail válido.').required('E-mail é obrigatório'),
        });

        await schema.validate(formdata, {
          abortEarly: false,
        });

        if(token) {
          const api = apiAuth(token);
         
          const { data } = await api.put('/users/' + userSelected?.id, {
            ...formdata,
            role: updateRoleUser.value,
          });
         
          if(data) {
            const newListUsers = users.map(user => {
              if(user.id === userSelected?.id) {
                return {
                  ...data,
                  role_format: data.role === "administrator" ? "Administrador" : "Usuário", 
                  created_format: (new Date(data.created_at)).toLocaleDateString('pt-BR'),
                }
              }
              return user;
            });
            setUsers(newListUsers); 
            setIsOpen(false);
            setIsOpenViewModal(false);
            toast.success('Usuário atualizado com sucesso');
          }
        }

      } catch (err) {
        if(err instanceof Yup.ValidationError) {
          const validationErrors = getValidationErrors(err); 

           updateFormRef.current?.setErrors(validationErrors);

           return toast.error('Verifique os dados preenchidos');
         }
      } finally {
        setIsLoading(false);
      }

    }

    function onChangeRoleUserRegister(value: unknown) {
      if(value) {
        // @ts-ignore
        setRoleUserRegister(value.value);
      }
    }

    function onChangeRoleUserUpdated(value: unknown) {
      if(value) {
        // @ts-ignore
        setUpdateRoleUser(value);
      }
    }

    async function handleDeleteUser() {
     
      const confirmed = await Swal.fire({
        title: 'Deseja realmente apagar este usuário ?',
        icon: 'error',
        html: `Você está preste a deletar o usuário <strong style="color: ${theme.colors.danger};">${userSelected?.first_name}</strong>, ` +
        ' confirme abaixo para deletar.',
        confirmButtonText: 'Deletar agora',
        cancelButtonText: 'Cancelar',
        focusConfirm: true,
        confirmButtonColor: theme.colors.danger,
        iconColor: theme.colors.danger,
        showCancelButton: true,
        cancelButtonColor: theme.colors.text,
        cancelButtonAriaLabel: 'Cancelar',
        showCloseButton: true,
      });

      if(confirmed.isConfirmed) {
        if(token) {
          try {
            const api = apiAuth(token);
            
            await api.delete(`/users/${userSelected?.id}`);
            /** Update list users */
            const newListUsers = users.filter(user => user.id !== userSelected?.id);
            setUsers(newListUsers);
            /** Set null state user selected */
            setIsOpenViewModal(false);
            setUserSelected(null);
            toast.success('Usuário deletado com sucesso');

          } catch (error: any) {
            if(error.response) {
              toast.error('Houve um erro ao deletar usuário');
            }
          }
        }
      }
    }

    function handleOpenRegisterUser() {
      registerFormRef.current?.reset();
      setIsOpen(true);
    }

    return (
      <>
        {/* MODAL REGISTER NEW USER */}
        <ModalContent isOpen={isOpen} title='Cadastrar usuário' onRequestClose={() => setIsOpen(false)}>
          <FormContainer>
            <Form onSubmit={handlSubmitRegisterUser} ref={registerFormRef}>
              <div className='input-group'>
                <Input 
                  icon={FiUser}
                  name='first_name' 
                  placeholder='Nome' 
                />
                
                <Input 
                  icon={FiUser}
                  name='last_name' 
                  placeholder='Sobrenome' 
                />

                <Input 
                  icon={FiMail}
                  name='email' 
                  placeholder='E-mail' 
                />
                
                <Input 
                  icon={FiLock}
                  name='password' 
                  placeholder='Senha' 
                  type="password"
                />
                
                <Select 
                  name="role"
                  icon={FiSettings}
                  options={[
                    {value: 'administrator', label: 'Administrador'},
                    {value: 'user', label: 'Usuário comum'},
                  ]}
                  onChange={onChangeRoleUserRegister}
                  placeholder="Função"
                  defaultValue={updateRoleUser}
                />

              </div>
              <Button title='Cadastrar' type='submit' isLoading={isLoading}/>
            </Form>
          </FormContainer>
        </ModalContent>
        {/* END MODAL REGISTER NEW USER */}
        <ModalContent isOpen={isOpenViewModal} title='Visualizar usuário' onRequestClose={() => setIsOpenViewModal(false)}>
          <FormContainer>
            <Form onSubmit={handleSubmitUpdateUser} ref={updateFormRef}>
              <div className='input-group'>
                {userSelected && (
                  <>
                    <Input 
                      icon={FiUser}
                      name='first_name' 
                      placeholder='Nome'
                    />
                    
                    <Input 
                      icon={FiUser}
                      name='last_name' 
                      placeholder='Sobrenome' 
                    />
    
                    <Input 
                      icon={FiMail}
                      name='email' 
                      placeholder='E-mail' 
                    />
                    
                    <Input 
                      icon={FiLock}
                      name='password' 
                      placeholder='Senha' 
                      type="password"
                      autoComplete='false'
                    />
                    
                    <Select 
                      name="role"
                      icon={FiSettings}
                      options={[
                        {value: 'administrator', label: 'Administrador'},
                        {value: 'user', label: 'Usuário comum'},
                      ]}
                      defaultValue={updateRoleUser}
                      value={updateRoleUser}
                      onChange={onChangeRoleUserUpdated}
                      placeholder="Função"
                    />
                  
                  </>
                )}

              </div>
              <div>
                <Button 
                  title='Atualizar usuário' 
                  type='submit'
                  isLoading={isLoading}
                />
                <Button 
                  title='Deletar usuário' 
                  type='button'
                  colorSchema='danger'
                  style={{ marginTop: 8 }}
                  isLoading={isLoading}
                  onClick={handleDeleteUser}
                />
              </div>
            </Form>
          </FormContainer>
        </ModalContent>
        <Container>
          <TopMenu>
            <h1>Usuários</h1>
            <div style={{ width: 250 }}>
              <Button 
                title='Cadastrar usuário'
                onClick={handleOpenRegisterUser} 
              />

            </div>
          </TopMenu>
          <Content>
            <Table>
              <thead>
                <tr>
                  <th style={{ textAlign: 'start'}}>Nome</th>
                  <th style={{ textAlign: 'start'}}>Sobrenome</th>
                  <th style={{ textAlign: 'start'}}>E-mail</th>
                  <th style={{ textAlign: 'start'}}>Função</th>
                  <th style={{ textAlign: 'start'}}>Data de criação</th>
                </tr>
              </thead>
              <tbody>
                {users.map(user => (
                  <Item 
                    key={user.id}
                    onClick={() => handleOpenViewUser(user.id)}
                  >
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.email}</td>
                    <td>{user.role_format}</td>
                    <td>{user.created_format}</td>
                  </Item>
                ))}
              </tbody>
            </Table>
          </Content>
        </Container>
      </>
    );
}