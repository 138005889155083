import { CSSProperties, ReactNode, useEffect } from 'react';
import { motion, Variants } from 'framer-motion';
import { FiX } from 'react-icons/fi';
import { ButtonCloseModal, Container, Header } from './styles';

const styles: CSSProperties = {
    position: 'fixed',
    display: 'none',
    height: '100vh',
    right: 0,
    top: 0,
    zIndex: 99,
    background: 'white',
    boxShadow: 'rgb(0 0 0 / 5%) -8px 10px 10px',
};

const overlayContainer: CSSProperties = {
    position: 'fixed',
    background: 'rgba(0,0,0,0.08)',
    height: '100vh',
    width: '100%',
    zIndex: 98,
};

interface Props {
    isOpen: boolean;
    onRequestClose: () => void;
    children?: ReactNode;
    title?: string;
};

const variants: Variants = {
    open: {
        display: 'flex',
        width: 600,
        visibility: 'visible',
        transition: {
            type: "spring",
            stiffness: 260,
            damping: 20,
        }
    },
    closed: {
        opacity: 0,
        width: 0,
        visibility: 'hidden',
        display: 'none',
        transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 300,
            damping: 25
        }
    }
};

const variantsOverlay: Variants = {
    open: {
        opacity: 1,
        visibility: 'visible'
    },
    closed: {
        opacity: 0,
        visibility: 'hidden',
        
    }
}

const variantsContent: Variants = {
    open: {
        opacity: 1,
        transition: {
            delay: 0.5,
        }
    },
    closed: {
        opacity: 0,
    }
}

export function ModalContent({ isOpen = false, onRequestClose, title, children }: Props) {

    function handleOnRequestClose() {
        if(isOpen) {
            onRequestClose();
        }
    }

    function handleEscRequestClose(e: any) {
        if(e.key === "Escape") {
            onRequestClose();
        }
    }

    useEffect(() => {
        document.addEventListener('keyup', handleEscRequestClose);

        return () => document.removeEventListener('keyup', handleEscRequestClose);
    },);

    return (
        <motion.div 
            style={overlayContainer}
            animate={isOpen ? "open": "closed"}
            variants={variantsOverlay}
        >
            <motion.div
                style={styles}
                animate={isOpen ? "open": "closed"}
                variants={variants}
                                
            >
                <motion.div
                    animate={isOpen ? "open": "closed"}
                    variants={variantsContent}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                >  
                    
                    <Header>
                        <h1>{title}</h1>
                        <ButtonCloseModal onClick={handleOnRequestClose}>
                            <FiX size={24}/>
                        </ButtonCloseModal>
                    </Header>
                    <Container>
                       {children}
                    </Container>
                </motion.div>
            </motion.div>
        </motion.div>
    )
}