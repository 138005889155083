import { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import { FiLock } from 'react-icons/fi';
import logotipo from '../../assets/logo.svg';
import { Form } from '@unform/web';
import { Link, useSearchParams, useNavigate} from 'react-router-dom';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import {
  Container,
  Wrapper,
  ContainerForm,
} from './styles';
import { Loading } from '../../components/Loading';
import { getValidationErrors } from '../../utils/getValidationErrors';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { api } from '../../utils/api';

type FormData = { password: string; password_confirm: string };

export function ResetPassword()
{
    const formRef = useRef<FormHandles>(null);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ userToken, setUserToken ] = useState<string | null>(null);

    useEffect(() => {
        const token = searchParams.get('token');
        
        if(!token)
            return navigate('/');
        
        setUserToken(token);
    }, [searchParams, navigate]);

    async function handleOnSubmit(data: FormData) {
        
        try {
            formRef.current?.setErrors({})
            if(!userToken) return;

            setIsLoading(true);
          
            const schema = Yup.object().shape({
                password: Yup.string().min(6, 'A senha deve ter pelo menos 6 caracteres').required('Informe sua nova senha'),
                password_confirm: Yup.string().min(6, 'A senha deve ter pelo menos 6 caracteres').required('Confirme a sua nova senha.')
            });
            console.log(data.password_confirm !== data.password,data.password_confirm, data.password, data);
            
            if(data.password_confirm !== data.password) {
                return formRef.current?.setErrors({
                    password_confirm: 'Suas senhas não se concidem, tente novamente.',
                });

            }
            
            await schema.validate(data, { 
                abortEarly: false,
            });
            
            await api.post('/password/reset', {
                ...data,
                token: userToken,
            });
            
            
            toast.success('Senha atualizada com sucesso, faça seu login.');

            navigate('/');
            
        } catch (error: any) {
            if(error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error);
                formRef.current?.setErrors(errors);

                return toast.error('Verifique o e-mail informado.');
            }   

            return toast.error(error.response.data.message);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <Container>
                <Wrapper>
                    <img src={logotipo} alt="Análise de Resultado" />
                    <ContainerForm>
                        <header>
                            <p><strong>Criar nova senha</strong></p>
                        </header>
                        <Form onSubmit={handleOnSubmit} ref={formRef}>
                            <Input 
                                name='password'
                                placeholder='Nova senha'
                                type="password"
                                autoComplete="false"
                                icon={FiLock}
                                />
                            <Input 
                                name='password_confirm'
                                placeholder='Confirme sua nova senha'
                                type="password"
                                autoComplete="false"
                                icon={FiLock}
                            />
                            <Button 
                                title="Entrar"
                                type='submit'
                            />
                        </Form>
                    </ContainerForm>
                    <p>
                        Lembrou da senha? <Link to="/">Efetuar login.</Link>
                    </p>
                </Wrapper>
            </Container>
        </>
    );
}   