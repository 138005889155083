import styled, { css, keyframes } from "styled-components";
import { shade } from 'polished';

type Props = { 
    colorSchema: 'danger' | 'success' | 'default'; 
};

const colors = {
    danger: css`
        background: ${({ theme }) => theme.colors.danger};
    `,
    success: css`
        background: ${({ theme }) => theme.colors.success};
    `,
    default: css`
        background: ${({ theme }) => theme.colors.primary};
    `,
}

export const Container = styled.button<Props>`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 10px;
    border: 0;
    font-weight: bold;
    transition: filter 0.2s;

    ${({ colorSchema }) => colors[colorSchema]}
    
    &:hover {
        filter: brightness(0.8);
    }
    
    &:disabled {
        opacity: .6;
    }
`;

const animationLoading = keyframes`
    to {
        transform: rotate(1turn);
    }
`;

export const Loading = styled.div<Props>`
    height: 24px;
    width: 24px;
    border-radius: 24px;
    border: 5px solid ${(props) => {
        const color = props.colorSchema === "default" ? props.theme.colors.primary : props.theme.colors[props.colorSchema];
        return shade(0.25, color);
    }};
    border-top-color: #fff;
    animation: ${animationLoading} 1s infinite;
`;