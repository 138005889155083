import './styles/global.css';
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/dist/sweetalert2.css';

import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import { Routes } from './routes';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './hooks/Auth';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
            <Routes />
            <ToastContainer />
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
