import styled, { css } from 'styled-components';

interface ContainerProps {
    isFocused?: boolean;
    isFilled?: boolean;
    isErrored?: boolean;
}

export const ContainerInput = styled.div<ContainerProps>`
    width: 100%;
    height: 40px;

    background: var(--background-sidebar);
    border-radius: 10px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    border: 2px solid transparent;
    transition: border .2s;

    ${({ theme, isErrored }) => isErrored && css`
        border-color: ${theme.colors.danger};
    `}
    
    ${({ theme, isFocused }) => isFocused && css`
        border-color: ${theme.colors.primary};
    `}
    
    input {
        width: 100%;
        height: 100%;
        border: 0;
        background: transparent;
        color: var(--title);

        &:placeholder {
            color: var(--text);
        }
    }

    svg {
        margin-right: .5rem;
        transition: color .2s;
        color: ${({ isFocused, isFilled, theme }) => isFocused || isFilled ? theme.colors.primary : theme.colors.text};
    }
`;
